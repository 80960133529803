.oho__flex {
  display: flex;
  flex-direction: column;
}

.oho__flexStart {
  align-items: flex-start;
}

.oho__flexGrow {
  flex: 1;
}

.oho__flexRow {
  flex-direction: row;
}

.oho__alignCenter {
  align-items: center;
}

.oho__gap8 {
  gap: 0.5rem;
}

.oho__gap16 {
  gap: 1rem;
}

.oho__gap24 {
  gap: 1.5rem;
}

.oho__gap40 {
  gap: 2.5rem;
}

@include breakpoint(large) {
  .lg\:oho__flexGrow {
    flex: 1;
  }

  .lg\:oho__flexRow {
    flex-direction: row;
  }

  .lg\:oho__flexStart {
    align-items: flex-start;
  }

  .lg\:oho__gap8 {
    gap: 0.5rem;
  }

  .lg\:oho__gap16 {
    gap: 1rem;
  }

  .lg\:oho__gap24 {
    gap: 1.5rem;
  }

  .lg\:oho__gap32 {
    gap: 2rem;
  }

  .lg\:oho__gap40 {
    gap: 2.5rem;
  }

  .lg\:oho__gap60 {
    gap: 3.75rem;
  }

  .lg\:oho__alignCenter {
    align-items: center;
  }
}
