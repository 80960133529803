.alert {
  background-color: $yellow;
  padding: 1rem 0;

  a {
    @include link-underline-offset($offset: 1px);
  }

  svg {
    color: $blue-x-dark;
  }

  .alert__content {
    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .alert__container {
    display: flex;
    align-items: center;
    gap: 0.875rem;
  }
}
