.events-feature {
  padding: 2.5rem 0;

  .events-feature__c {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .events-feature__items {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    p {
      margin: 0;
    }
  }

  .events-feature__item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .events-feature__item__img img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0;
  }

  .events-feature__item__body,
  .events-feature__item__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .events-feature__item__heading {
    @include typeface(h6-caps);
    @include typeface(h6-color);
    margin: 0;
  }

  @include breakpoint(small) {
    .events-feature__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 2.5rem;
    }
  }

  @include breakpoint(large) {
    padding: 5rem 0;

    .events-feature__c {
      gap: 2.5rem;
    }

    .events-feature__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: stretch;
      gap: 2.5rem;
    }

    .events-feature__item {
      gap: 1.5rem;
    }

    .events-feature__item__content {
      gap: 1rem;
    }
  }
}
