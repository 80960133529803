////
/// @group wrapper
/// Main header styles.
////

$logo-width-mobile: 140px;
$logo-width-desktop: 260px;

$header-height-mobile: 58px;
$logo-height-mobile: 33px;

$max-logo-width: $logo-width-mobile;
$max-logo-width-desktop: $logo-width-desktop;

$menu-mobile-padding-top: 1.5rem;

@mixin utility-font {
  font-size: 1rem;
  font-weight: 700;
  line-height: 125%;
}

@mixin header-menu {
  @include list-reset;

  span {
    display: block;
  }

  a {
    display: block;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

@mixin header-utility-menu {
  @include header-menu;

  /// Mobile
  /// ------
  @include breakpoint(medium down) {
    width: 100%;

    ul {
      column-count: 2;
      width: 100%;
    }

    li,
    a {
      display: inline-block;
      width: 100%;
      break-inside: avoid;
      page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
      margin: 0;
    }

    li {
      display: block;
      background: $blue-x-dark;
      border: 1px solid $blue-x-dark;
    }

    a {
      background: $blue-x-dark;
      color: $white;
      text-decoration-color: $white;
      line-height: 150%;
      margin: 0 0 0.5rem;
    }
  }

  @include breakpoint(large) {
    ul {
      display: flex;
    }

    li {
      flex: 1 0 auto;
    }

    a {
      padding: 0;
    }

    /// First level
    & > ul {
      display: flex;
      align-items: center;
      gap: 1.5rem;

      & > li.menu-item,
      & > li.menu-item > span.menu-item__actions {
        display: flex;
        align-items: center;
      }
    }
  }
}

@mixin header-main-menu {
  @include header-menu;

  /// All Levels
  /// ----------
  ul {
    display: flex;
  }

  /// Mobile
  /// -----
  @include breakpoint(medium down) {
    width: 100%;
    color: $white;

    ul {
      flex-direction: column;
      width: 100%;
    }

    a {
      display: block;
      color: inherit;
    }

    .menu-item__button {
      @include arrow-menu-arrow;
    }

    /// First level
    & > ul {
      gap: 1rem;

      & > li > .menu-item__actions {
        @include arrow-menu-wrapper;

        a {
          @include arrow-menu-text($caps: true);
        }
      }

      & > li.menu-item--open {
        @include arrow-menu-container;

        .menu-item__button {
          @include arrow-menu-arrow-open;
        }

        .menu {
          @include arrow-menu-menu;
        }
      }

      /// Second Level
      /// ------------
      ul {
        display: none;
      }
    }
  }

  /// Desktop
  /// -----
  @include breakpoint(large) {
    .menu-item__button {
      &:not(:focus) {
        @include element-invisible;
      }

      &:focus {
        @include button-reset;
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 0.1rem);
        width: auto;
        height: auto;
        text-align: center;
        color: $white;
        border: 0;
        background: $red;
        padding: 0.25rem;
        font-size: 0.7em;
        line-height: 1;
      }
    }

    /// First level
    & > ul {
      display: flex;
      flex: 1 0 auto;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      column-gap: 1.5rem;
      row-gap: 0.25rem;

      // Change the number here to include more right side dropdowns.
      > li:nth-last-child(-n + 2) > ul {
        right: -1.5rem; /* to align right side with dropdown notch */
      }

      & > li.menu-item,
      & > li.menu-item > span.menu-item__actions {
        display: flex;
        align-items: center;
      }

      & > li {
        position: relative;

        & > .menu-item__actions a {
          text-transform: uppercase;
          line-height: 1.75rem;
          padding: 0;
        }

        /// Active item
        &.menu-item--active-trail > .menu-item__actions a {
          text-decoration: underline;
        }

        &.menu-item--children > .menu-item__actions a {
          &:hover,
          &:focus {
            text-decoration: none;
          }
        }

        &:hover {
          > ul {
            display: flex;
          }
        }
      }

      /// Second Level
      /// ------------
      ul {
        @include notch($size: 24px);
        position: absolute;
        z-index: 20;
        top: 100%;
        display: none;
        flex-direction: column;
        width: auto;
        min-width: 17rem;
        padding: 1.5rem;
        color: $white;
        background: $red;
        gap: 0.5rem;

        a {
          @include link-on-background($bg: 'red', $only-active: true);
          line-height: 150%;
        }
      }
    }
  }
}

@mixin header-site-search {
  position: fixed;
  z-index: 20;
  top: $header-height-mobile;
  right: 0;
  width: 100%;
  display: none;
  filter: drop-shadow(0 4px 15px rgba(0, 0, 0, 0.25));

  .site-search__container {
    background: $white;
    padding: 1rem;
  }

  .form__field--search {
    gap: 0;

    .form__field__container {
      @include notch;
      @include notch-border($color: $red);
      display: flex;
      gap: 0.5rem;
      width: 100%;
      padding: 0.5rem 0.5rem 0.5rem 12px;

      @include breakpoint(large) {
        padding: 0.625rem;
      }
    }

    .site-search__field__container {
      display: flex;
      flex: 1;
      padding: 0.5rem 1rem;
      @include notch;

      &:focus-within {
        background-color: #eee;
      }
    }

    input.site-search__field {
      display: block;
      flex: 1;
      width: 100%;
      background: transparent;
      font-size: 1.25rem;
      line-height: 1;
      border: 0;
      padding: 0.5rem;
      margin: 0;
      min-width: unset;
      outline: 0;

      &:focus {
        background-color: #eee;
      }

      @include placeholder {
        color: $black;
      }
    }
  }

  /// Desktop
  /// -------
  @include breakpoint(large) {
    position: absolute;
    top: calc(100% + 8px);
    right: -2.5rem;
    width: auto;
    min-width: 720px;

    .site-search__container {
      @include notch($size: 24px);
      background: $white;
      padding: 2.5rem;
    }

    .form__field--search {
      gap: 0.75rem;

      label {
        @include typeface(h5);
        @include typeface(h5-color);
        margin: 0;
      }
    }
  }
}

@mixin site-header-silhouette($color: 'blue-light') {
  .site-header__bg__img {
    @include silhouette-bg-img($color: $color);
  }

  .site-header__bg {
    @include silhouette-bg($color: $color, $width: 100%, $height: 100%);
  }

  &.site-header--bgfixed .site-header__bg.site-header__bg--root {
    @include silhouette-bg($color: $color, $width: 100%, $height: 178px);
  }

  @include breakpoint(large) {
    &.site-header--bgfixed .site-header__bg.site-header__bg--root {
      @include silhouette-bg($color: $color, $width: 100%, $height: 577px);
    }
  }
}

/// Body when mobile menu is open
@include breakpoint(medium down) {
  html.oho-js body.mobile-menu-open {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

/// Header Styles
/// -------------
.site-header {
  @include contain-silhouette-bg;
  z-index: 100;
  color: $blue-x-dark;

  .site-header__top {
    z-index: 200;
  }

  // Hide the container bg unless sticky.
  .site-header__container {
    @include contain-silhouette-bg;

    .site-header__bg {
      display: none;
    }
  }

  &:not(.site-header--blue) {
    @include site-header-silhouette;
  }

  &.site-header--blue {
    @include site-header-silhouette($color: 'blue');
  }

  .site-header__logo {
    display: flex;
    align-items: center;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    .site-header__logo__link {
      display: inline-block;
      padding: 0;
    }

    img {
      width: 100%;
      max-width: $max-logo-width;
      height: auto;
      margin: 0;
    }
  }

  .site-header__logo__black {
    display: block;
  }

  .site-header__logo__white {
    display: none;
  }

  .site-header__search {
    display: flex;
    align-items: center;
  }

  .site-header__search__button {
    @include button-reset;
    color: inherit;

    .site-header__search__button__icon {
      display: block;
      margin: 0;
      font-size: 1.5rem;
      line-height: 1;
      color: inherit;

      &.site-header__search__button__close {
        display: none;
        padding: 0.125rem;
      }
    }
  }

  /// Utility menu
  .site-header__utility__menu {
    @include header-utility-menu;
  }

  /// Main menu
  .site-header__main__menu {
    @include header-main-menu;
  }

  /// Search styles
  .site-search {
    @include header-site-search;
  }

  .site-header__inner {
    height: 100%;
  }

  .site-header__hero {
    position: relative;
    z-index: -1;
  }

  &.site-search-open {
    .site-search {
      display: block;
    }
    .site-header__search__button__icon {
      &.site-header__search__button__mag {
        display: none;
      }
      &.site-header__search__button__close {
        display: block;
      }
    }
  }

  @include breakpoint(medium down) {
    .site-header__inner {
      display: grid;
      align-items: flex-start;
      column-gap: 1.5rem;
      grid-template-columns: 1fr auto auto;
      grid-template-rows: $header-height-mobile;
    }

    .site-header__logo,
    .site-header__search,
    .site-header__mobile-menu-toggle {
      align-self: center;
    }

    .site-header__search {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }

    .site-header__mobile-menu-toggle {
      display: flex;
      align-items: center;
      grid-column: 3 / -1;
      grid-row: 1 / span 1;
    }

    .site-header-mobile-menu-toggle {
      @include button-reset;
      position: relative;
      width: 1.5rem;
      height: 1.5rem;

      .bar {
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
        height: 4px;
        transition: background-color $primary-transition,
          opacity $primary-transition, width $primary-transition,
          transform $primary-transition;
        transform-origin: center;
        opacity: 1;
        border-radius: 0.25rem;
        background: $blue-x-dark;

        &.bar-2,
        &.bar-3 {
          top: 10px;
        }

        &.bar-4 {
          top: 19px;
        }
      }

      &[aria-expanded='true'] {
        .bar {
          &.bar-1,
          &.bar-4 {
            opacity: 0;
          }

          &.bar-2,
          &.bar-3 {
            left: -7%;
            width: 114%;
          }

          &.bar-2 {
            transform: rotate(45deg);
          }

          &.bar-3 {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .site-header-section,
    .site-header__menu {
      display: none;
    }

    &.site-header--blue {
      color: $white;

      .site-header-mobile-menu-toggle .bar {
        background: $white;
      }
    }

    html.oho-js body.mobile-menu-open & {
      position: fixed;
      top: 0;
      left: 0;
      overflow: auto;
      width: 100%;
      height: 100%;
      background: $blue-x-dark;

      .site-header__bg {
        display: block;
        height: $header-height-mobile;

        &.site-header__bg--root {
          display: none;
        }
      }

      .site-header__inner {
        grid-template-rows: $header-height-mobile auto 1fr;
      }

      .site-header__menu {
        display: flex;
        width: 100%;
      }

      .site-header__menus {
        display: flex;
        flex-direction: column;
        width: 100%;
        grid-column: 1 / -1;
        grid-row: 3 / span 1;
      }

      .site-header__audience,
      .site-header__utility {
        padding: $menu-mobile-padding-top 0 0 0;
      }

      .site-header__menu__content {
        padding: $menu-mobile-padding-top 0;
      }

      .site-header-mobile-menu-toggle .bar {
        background: $red;
      }

      &.site-header--blue {
        .site-header-mobile-menu-toggle .bar {
          background: $blue-light;
        }
      }

      .site-header__hero {
        display: none;
      }

      .site-header-section {
        display: flex;
        width: 100%;
        flex-direction: column;

        .site-header-section__button {
          @include arrow-menu-wrapper;
          background: $blue-dark;
          margin-left: 0 - 1.5rem; // 1.5rem padding on each side
          width: calc(100% + 3rem); // Total padding is 3rem (1.5 on each side)
          padding: 1.5rem 1.5rem 0.75rem;

          @include breakpoint(medium) {
            margin-left: 0 - 2.5rem; // 1.5rem padding on each side
            width: calc(
              100% + 5rem
            ); // Total padding is 3rem (1.5 on each side)
            padding-left: 2.5rem;
            padding-right: 2.5rem;
          }

          .site-header-section__button__text {
            @include arrow-menu-text;
            text-transform: uppercase;
          }

          .site-header-section__button__icon {
            @include arrow-menu-arrow($background: $white, $color: $red);
          }
        }

        .site-header-section__content {
          display: none;
        }

        &.site-header-insection {
          .site-header-section__button {
            padding-top: 0.75rem;
            padding-bottom: 1.5rem;
          }
        }

        &.site-header-section--open {
          .site-header-section__button {
            padding-bottom: 0;

            .site-header-section__button__icon {
              @include arrow-menu-arrow-open(
                $background: $blue-x-dark,
                $color: $white
              );
            }
          }

          .site-header-section__content {
            display: block;
          }
        }
      }
    }

    :root:not(.oho-js) & {
      .site-header__js {
        display: none;
      }

      .site-header__logo {
        grid-column: 1 / -1;
      }
    }
  }

  @include breakpoint(medium) {
    // To have card overflow.
    &.site-header--home {
      .site-header__bg {
        height: calc(100% - 1.5rem);
      }
    }
  }

  @include breakpoint(large) {
    $audience-utility-padding-bottom: 0.15rem;

    .site-header__inner {
      display: grid;
      padding: 2.75rem 0;
      grid-template-columns: $logo-width-desktop 1fr auto;
      grid-template-rows: auto 1fr;
      row-gap: 1rem;
      column-gap: 1.5rem;
      align-items: center;

      a {
        color: $blue-x-dark;
        text-decoration-color: $blue-x-dark;
      }
    }

    .site-header__mobile-menu-toggle {
      display: none;
    }

    .site-header__logo {
      align-items: center;
      grid-column: 1 / span 1;
      grid-row: 1 / span 2;

      img {
        max-width: $max-logo-width-desktop;
      }
    }

    .site-header__search {
      position: relative;
      grid-column: 3 / span 1;
      grid-row: 1 / span 1;
    }

    .site-header__menus {
      display: grid;
      align-items: center;
      grid-column: 2 / span 2;
      grid-row: 1 / span 2;
    }

    .site-header__mainmenus {
      display: grid;
      align-items: center;
    }

    .site-header-main-menus {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-template-columns: 1fr auto;
      align-items: center;
      column-gap: 1.5rem;
      row-gap: 1rem;
    }

    .site-header__main {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      grid-column: 1 / span 2;
      grid-row: 2 / span 1;
    }

    .site-header__utility {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 0 $audience-utility-padding-bottom;
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;

      a {
        @include utility-font;
      }
    }

    .site-header__audience {
      display: flex;
      align-items: center;
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
      position: relative;
      margin-right: 3rem; /* 1.5rem for search button, 1.5rem for space */

      a,
      button {
        @include utility-font;
      }

      .site-header__audience__button {
        @include button-reset;
        @include utility-font;
        display: flex;
        align-items: center;
        padding: 0 0 $audience-utility-padding-bottom;
        color: inherit;
        border-bottom: 1px solid rgba(1, 33, 103, 0.5);
        gap: 0.5rem;

        svg {
          font-size: 0.5rem;
          line-height: 1;
        }
      }

      .site-header__arrowmenu {
        @include arrow-menu-nav;
        @include notch($size: 24px);
        position: absolute;
        z-index: 20;
        top: calc(100% - 2px); // For bottom border
        right: -24px; // For notch
        display: none;
        width: auto;
        min-width: 14rem;
      }

      &:hover,
      &.arrow-menu-mobile--open {
        .site-header__arrowmenu {
          display: block;
        }
      }
    }

    .site-header-section__button,
    .site-header-insection {
      display: none;
    }

    // To have card overflow.
    &.site-header--profile {
      .site-header__bg {
        height: calc(100% - 1.5rem);
      }
    }

    &.site-header--profile {
      background: $blue-x-dark;
    }

    &.site-header--blue {
      color: $white;
      text-decoration-color: $white;

      .site-header__top a {
        color: inherit;
        text-decoration-color: $white;
      }

      .site-header__utility__menu a,
      .site-header__audience__button,
      .site-header__search__button {
        color: $blue-light;
        text-decoration-color: $blue-light;
      }
    }

    .site-header__sticky-container {
      height: 100%;
      will-change: transform;

      & > .grid-container {
        position: relative;
        height: 100%;
      }
    }

    /// Sticky Behavior
    /// ---------------
    &.site-header--beyond-threshold {
      .site-header__sticky-container {
        position: fixed;
        z-index: 10;
        top: 0;
        width: 100%;
        height: auto;
        transform: translateY(-100%);
        background: $white;
      }

      .site-header__inner {
        padding: 2.5rem 0;
      }

      // Show bg on sticky.
      .site-header__top {
        .site-header__bg {
          display: block;
          height: 100%;
        }
      }
    }

    // Animation is added on a separate class, which is added to the DOM slightly
    // after the sticky class, so that the initial animation out is not shown.
    &.site-header--beyond-threshold-animate {
      .site-header__sticky-container {
        transition: transform $primary-transition;
      }
    }

    &.site-header--scroll-up,
    &.site-header--at-bottom {
      .site-header__sticky-container {
        transform: translateY(0);
      }
    }
  }

  &.site-header--blue {
    .site-header__logo__black {
      display: none;
    }

    .site-header__logo__white {
      display: block;
    }
  }
}
