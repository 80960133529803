////
/// @group components
/// Styles for differentiators
////

.differentiators {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;

  .differentiator {
    display: flex;
    width: 100%;
    flex: 1;
  }

  @include breakpoint(medium) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 32%); // This specific setup is required for Windows Firefox to work with notches.

    .callout.callout--stat {
      .callout__content {
        display: grid;
        height: 100%;
      }
      .callout__action {
        display: flex;
        align-self: flex-end;
      }
    }
  }
}
