////
/// @group components
/// Styles for the more-or-less.
/// @see more-or-less.js for functionality
////

.more-less {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5rem;

  [data-more-less-content] {
    position: relative;
    overflow: hidden;
  }

  // If the more-less hasn't init, we need to hide the button.
  [data-more-less-toggle],
  .more-less__toggle {
    display: none;
  }

  // if the more-less has init show the button
  &[data-more-less-init] {
    [data-more-less-toggle],
    .more-less__toggle {
      display: inline-block;
    }
    [data-more-less-item]:nth-child(9) ~ [data-more-less-item] {
      display: none;
    }
  }

  // When the more-less is open, unset the max height and show all the items
  &.more-less-open {
    [data-more-less-content] {
      max-height: none !important;
    }

    [data-more-less-item] {
      display: block !important;
    }
  }
}

// If there aren't enough items to trigger the more or less, or when JavaScript
// doesn't load for some reason, show the all the content and hide the button
// and overlay.
:root:not(.oho-js) .more-less {
  &__content {
    max-height: none !important;
  }

  &__item {
    display: block !important;
  }
}

:root:not(.oho-js) .more-less:after,
:root:not(.oho-js) .more-less__toggle,
.more-less--inactive:after,
.more-less--inactive .more-less__toggle {
  display: none;
}
