////
/// @group wrapper
/// Main footer styles.
////

$max-mobile-width: rem-calc(360);
$aud-button-notch: 13px;

.site-footer {
  @include typeface-small-text(large);

  a {
    @include typeface-small-text-link(large);
  }

  .site-footer__contact a,
  .site-footer__menus a {
    @include link-on-background($only-active: true);
  }

  p {
    margin: 0;
  }

  address {
    font-style: normal;
  }

  .site-footer__top {
    background: $blue-x-dark;
    padding: 1.5rem 0;
    color: $white;

    a {
      color: $white;
    }
  }

  .site-footer__identity {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
  }

  .site-footer__logo {
    display: block;
    padding: 0;

    img {
      display: block;
      width: 100%;
      max-width: 22.5rem;
      margin: 0;
      height: auto;
    }
  }

  nav.social-media ul {
    justify-content: center;
  }

  .site-footer__contact {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    font-weight: bold;

    .site-footer__address {
      padding-bottom: 5px;
    }
  }

  .site-footer__contact__icon {
    color: $blue-light;
  }

  .site-footer__menus {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    a {
      display: block;
    }
  }

  .site-footer__menu ul {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .site-footer__bottom {
    padding: 1rem 0;
  }

  .site-footer__bottom__container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .site-footer__legal ul {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  @include breakpoint(small down) {
    .site-footer__identity {
      max-width: $max-mobile-width;
    }

    .site-footer__contact,
    .site-footer__menus,
    .site-footer__aud {
      width: 100%;
      max-width: $max-mobile-width;
      margin-top: 2.5rem;
    }

    .site-footer__menu--aud {
      display: none;
    }

    .site-footer__aud {
      margin-top: 1.5rem;
    }
  }

  @include breakpoint(medium) {
    .site-footer__logo img {
      max-width: 16rem;
    }

    .site-footer__container {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto 1fr;
      align-items: center;
      gap: 2.5rem;
    }

    .site-footer__menus {
      grid-column: 1 / -1;
      width: 100%;
      grid-template-columns: repeat(3, 1fr);
      gap: 2.5rem;
    }

    .site-footer__aud {
      display: none;
    }
  }

  @include breakpoint(large down) {
    .site-footer__contact__item {
      display: grid;
      grid-template-columns: minmax(1rem, auto) 1fr;
      gap: 0.5rem;

      .site-footer__contact__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.site-footer__address,
      &.site-footer__phone {
        line-height: 1.5;
      }

      &.site-footer__address {
        .site-footer__contact__icon {
          align-items: flex-start;
          padding-top: 4px;
        }
      }
    }
  }

  @include breakpoint(full) {
    .site-footer__logo img {
      max-width: 22.5rem;
    }

    .site-footer__top {
      padding: 4rem 0;
    }

    .site-footer__container {
      grid-template-columns: auto 1fr 3fr;
      grid-template-rows: unset;
      align-items: flex-start;
    }

    .site-footer__menus {
      grid-column: unset;
    }

    .site-footer__identity {
      gap: 2.5rem;
    }

    .site-footer__contact__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
    }

    .site-footer__bottom {
      padding: 1.5rem 0;
    }

    .site-footer__bottom__container {
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }

    .site-footer__legal ul {
      gap: 2rem;
    }
  }
}
