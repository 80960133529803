////
/// @group atoms
/// Styles for section headings.
////

.section-heading {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;

  .section-heading__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .section-heading__heading {
    &:not(.section-heading__heading--h3) {
      @include typeface(h2);
      margin: 0;
    }

    &.section-heading__heading--h3 {
      @include typeface(h3);
      margin: 0;
    }

    &.section-heading__heading--caps {
      text-transform: uppercase;
    }
  }

  .section-heading__desc {
    margin: 0;
  }

  .section-heading__actions {
    justify-self: flex-start;
  }

  .btn.section-heading__action {
    @include button($style: 'secondary', $init-defaults: false);
  }

  @include breakpoint(large) {
    gap: 1rem;

    &.section-heading--grid {
      display: grid;
      grid-template-columns: minmax(auto, 72%) auto;
      grid-template-rows: auto;
      align-items: center;
      column-gap: 2rem;
      row-gap: 1rem;

      .section-heading__content {
        grid-column: 1 / span 1;
      }

      &:not(.section-heading--desc) .section-heading__actions {
        grid-column: 2 / span 1;
        justify-self: flex-end;
      }

      &.section-heading--desc .section-heading__actions {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
      }
    }
  }

  .component.component--inverse & {
    .section-heading__heading,
    .section-heading__desc {
      color: $white;
    }

    a.section-heading__action {
      @include link-on-background($bg: 'blue');
    }

    .btn.section-heading__action {
      @include button($style: 'inverse-secondary', $init-defaults: false);
    }
  }
}
