.listings {
  .listings__filters {
    background: $blue-x-dark;
    padding: 1rem 0;
    color: $white;

    .listings__filters__container {
      @include notch-large($border: true);
      padding: 1.5rem 1rem;
    }

    .listings__form__heading {
      @include typeface(h2);
      color: $white;
      margin: 0;
    }

    @include breakpoint(medium down) {
      .listings__filters__container {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .grid-container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @include breakpoint(large) {
      padding: 2.5rem 0;

      .listings__filters__container {
        padding: 4rem 0;
      }
    }
  }

  .listings__results {
    padding: 4rem 0 2.5rem;
    border-bottom: 1px solid $red-light-opacity-half;

    .listings__results__heading {
      @include show-for-sr;
    }

    .listings__results__container {
      display: flex;
    }

    .listings__results__found {
      @include typeface(h6);
      @include typeface(h6-caps);
      @include typeface(h6-color);
      margin: 0;
    }
  }

  .listings__list {
    .listing {
      padding: 1.5rem 0;

      img {
        display: block;
        margin: 0;
      }

      h2,
      h3,
      h4,
      p {
        margin: 0;
      }

      .accordion {
        width: 100%;
      }

      .list-icons {
        gap: 1rem;
      }

      .listing__cols {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .tags-col {
        @include list-reset;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.25rem;

        a,
        button {
          display: block;
          width: 100%;
        }
      }

      .tags-csv {
        @include list-reset;

        li,
        span {
          display: inline;
        }
        a,
        button {
          display: inline-block;
        }
      }

      .listing__cell__info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .listing__cell__info__heading {
        @include typeface(body);
        margin: 0;
        color: inherit;
      }

      .listing__cell__highlight {
        @include typeface(h6);
        @include typeface(h6-color);
        margin: 0;
      }

      .listing__cell {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      &:nth-child(even) {
        background: $blue-x-light;
      }

      @include breakpoint(medium down) {
        .listing__container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;
        }

        .tags-level {
          margin-bottom: -1.5rem;
        }
      }

      @include breakpoint(large) {
        padding: 2.5rem 0;

        .listing__container {
          display: grid;
          gap: 2.5rem;
          align-items: flex-start;
        }

        &.listing--news {
          .listing__container {
            grid-template-columns: 360px 1fr;
          }
		  .listing__cell--image {
            grid-column: 1 / span 1;
          }
          .listing__cell--main {
            grid-column: 2 / span 1;
          }
        }

        &.listing--profile {
          .listing__container {
            grid-template-columns: 60px 160px 1fr 260px;
          }
		  .listing__cell--sort {
            grid-column: 1 / span 1;
          }
          .listing__cell--image {
            grid-column: 2 / span 1;
          }
          .listing__cell--main {
            grid-column: 3 / span 1;
          }
          .listing__cell--contact {
            grid-column: 4 / span 1;
          }
        }

        &.listing--program {
          .listing__container {
            grid-template-columns: 60px 1fr 360px;
          }
          .listing__cell--tag {
            grid-column: 1 / span 1;
          }
          .listing__cell--main {
            grid-column: 2 / span 1;
          }
          .listing__cell--info {
            grid-column: 3 / span 1;
          }
        }

        .lg\:listing__cell__right {
          text-align: right;
        }

        .listing__cell--levelTag {
          padding-top: 0.25rem;
        }

        .listing__cell__info {
          gap: 1rem;
        }

        .listing__cols {
          flex-direction: row;

          .listing__cols__col {
            flex: 1;
          }
        }
      }
    }
  }
}

.listings__pagination {
  padding: 1.5rem 0 2.5rem;
  border-top: 1px solid $red-light-opacity-half;

  @include breakpoint(large) {
    padding: 2.5rem 0 5rem;
  }
}
