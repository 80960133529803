////
/// @group utilities
/// Generates base rules for button styles.
/// $style defines the background & text colors for all states
///

@mixin button(
  $style: null,
  $init-defaults: false,
  $hover-bg-color: null,
  $hover-color: null
) {
  $notch_size: 16px;
  /// Shared Values between all states
  @if $init-defaults == true {
    display: inline-block;
    @include typeface(h5);
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    background: $red;
    color: $white;
    border: 0;
    padding: rem-calc(16px) rem-calc(24px);
    @include notch;
    @include notch-border($offset: 4px, $opacity: 0);

    &:focus {
      @include notch($invert: true);
      @include notch-border($color: $blue-x-dark, $width: 4px, $invert: true);
    }

    &:hover {
      background: $blue-dark;
      @include notch($invert: true);
      @include notch-border($offset: 4px, $invert: true);
    }

    @include breakpoint(large) {
      padding: rem-calc(24px) rem-calc(40px);
      @include notch-border($offset: 8px, $opacity: 0);

      &:hover {
        @include notch-border($offset: 8px, $invert: true);
      }
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.6;
    }
  }

  @if $style == 'inverse' or $style == 'inverse-secondary' {
    background: $white;
    color: $red;

    &:focus {
      background: $white;
      color: $red;

      &:before {
        background: $blue-light;
      }
    }

    &:hover {
      @if $hover-bg-color {
        background: $hover-bg-color;
      } @else {
        background: $blue-light;
      }
      @if $hover-color {
        color: $hover-color;
      } @else {
        color: $blue-x-dark;
      }
    }
  }

  @if $style == 'secondary' or $style == 'inverse-secondary' {
    @include breakpoint(large) {
      @include typeface(h6-caps);
      padding: rem-calc(16px) rem-calc(32px);
    }
  }
}

@mixin button-download($color: 'BA0C2F') {
  &:after {
    content: '';
    width: 1em;
    height: 1em;
    display: inline-block;
    margin: 0 0 0 0.5rem;
    vertical-align: text-bottom;
    background-image: url('data:image/svg+xml,%3Csvg viewbox="0 0 16 16" fill="%23#{$color}" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M6.75 0h2.5c.416 0 .75.334.75.75V6h2.74c.557 0 .835.672.441 1.066l-4.753 4.756a.606.606 0 0 1-.853 0l-4.76-4.756A.624.624 0 0 1 3.257 6H6V.75c0-.416.334-.75.75-.75ZM16 11.75v3.5c0 .416-.334.75-.75.75H.75a.748.748 0 0 1-.75-.75v-3.5c0-.416.334-.75.75-.75h4.584l1.532 1.531c.628.628 1.64.628 2.268 0L10.666 11h4.584c.416 0 .75.334.75.75Zm-3.875 2.75a.627.627 0 0 0-.625-.625.627.627 0 0 0-.625.625c0 .344.281.625.625.625a.627.627 0 0 0 .625-.625Zm2 0a.627.627 0 0 0-.625-.625.627.627 0 0 0-.625.625c0 .344.281.625.625.625a.627.627 0 0 0 .625-.625Z"/%3E%3C/svg%3E');
  }
}

@mixin button-external($color: 'BA0C2F', $hover-color: null) {
  &:after {
    content: '';
    width: 1em;
    height: 1em;
    display: inline-block;
    margin: 0 0 0 0.5rem;
    vertical-align: text-bottom;
    background-image: url('data:image/svg+xml,%3Csvg viewbox="0 0 14 14" fill="%23#{$color}" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M14 1.5v11a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 0 12.5v-11A1.5 1.5 0 0 1 1.5 0h11A1.5 1.5 0 0 1 14 1.5Zm-2.75.5h-3.5c-.665 0-1.002.808-.53 1.28l1 1-6.11 6.11a.375.375 0 0 0 0 .53l.97.97a.375.375 0 0 0 .53 0l6.11-6.11 1 1c.47.47 1.28.14 1.28-.53v-3.5a.75.75 0 0 0-.75-.75Z"/%3E%3C/svg%3E');
  }

  @if $hover-color {
    &:hover:after {
      background-image: url('data:image/svg+xml,%3Csvg viewbox="0 0 14 14" fill="%23#{$hover-color}" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M14 1.5v11a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 0 12.5v-11A1.5 1.5 0 0 1 1.5 0h11A1.5 1.5 0 0 1 14 1.5Zm-2.75.5h-3.5c-.665 0-1.002.808-.53 1.28l1 1-6.11 6.11a.375.375 0 0 0 0 .53l.97.97a.375.375 0 0 0 .53 0l6.11-6.11 1 1c.47.47 1.28.14 1.28-.53v-3.5a.75.75 0 0 0-.75-.75Z"/%3E%3C/svg%3E');
    }
  }
}

@mixin play-button(
  $width: auto,
  $button-width: 40px,
  $notch: true,
  $animate: true,
  $placement: 'center'
) {
  display: block;
  position: relative;
  width: $width;
  height: $width;
  padding: 0;

  .play-button__no-notch,
  .play-button__pause {
    display: none;
  }

  svg.fullscreen-button,
  svg.play-button {
    width: $button-width;
    height: $button-width;
    transform-origin: center;
    transition: width $primary-transition, height $primary-transition;
  }

  svg.play-button {
    position: absolute;
    left: 50%;
    @if $placement == 'bottom' {
      bottom: 0;
      transform: translateX(-50%);
    } @else {
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  svg.fullscreen-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  @if $animate == true {
    &:hover,
    &:focus {
      svg.fullscreen-button,
      svg.play-button {
        width: rem-calc(56px);
        height: rem-calc(56px);
      }
    }
  }

  @include breakpoint(medium) {
    svg.fullscreen-button,
    svg.play-button {
      width: rem-calc(56px);
      height: rem-calc(56px);
    }

    @if $animate == true {
      &:hover,
      &:focus {
        svg.fullscreen-button,
        svg.play-button {
          width: rem-calc(72px);
          height: rem-calc(72px);
        }
      }
    }
  }

  @if $notch == false {
    .play-button__notch {
      display: none;
    }
    .play-button__no-notch {
      display: block;
    }
  }

  .video-playing & {
    .play-button__play {
      display: none;
    }
    .play-button__pause {
      display: block;
    }
  }
}
