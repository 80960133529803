$horiz-padding: 0.5rem;
$horiz-exterior-padding: 1rem;

.pager {
  .pager__items {
    @include list-reset;
    @include secondary-typeface(small-text);
    display: flex;
    justify-content: center;
    gap: $horiz-padding / 2;

    a,
    button {
      @include button-reset;
      @include secondary-typeface(small-text-link);
      display: block;
      @include reset-text-decoration;
      padding: $horiz-padding / 2;
      color: $red;
    }

    .pager__item--first,
    .pager__item--previous {
      margin-right: $horiz-exterior-padding - $horiz-padding;
    }

    .pager__item--next,
    .pager__item--last {
      margin-left: $horiz-exterior-padding - $horiz-padding;
    }

    a[disabled],
    button[disabled],
    .pager__item.is-active a,
    .pager__item.is-active button {
      color: $blue-x-dark;
      text-decoration: none;
      pointer-events: none;
    }

    @include breakpoint(large) {
      $horiz-exterior-padding: 2.5rem;

      .pager__item--first,
      .pager__item--previous {
        margin-right: $horiz-exterior-padding - $horiz-padding;
      }

      .pager__item--next,
      .pager__item--last {
        margin-left: $horiz-exterior-padding - $horiz-padding;
      }
    }
  }
}
