.news-feature {
  background: $blue-x-light;
  padding: 2.5rem 0;

  &.news-feature--testimonials {
    background: transparent;
  }

  .news-feature__c {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .testimonial {
    background: $blue-x-light;
  }

  .news-feature__items {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    a {
      display: block;
    }

    p {
      margin: 0;
    }
  }

  .news-feature__item,
  .news-feature__item__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .news-feature__item__heading {
    @include typeface(h6-caps);
	@include typeface(h6-color);
    margin: 0;
  }

  @include breakpoint(large) {
    padding: 5rem 0;

    .news-feature__c {
      gap: 2.5rem;
    }

    .news-feature__items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: stretch;
      gap: 2.5rem;
    }

    .news-feature__item {
      display: grid;
      grid-template-rows: auto 1fr auto;
      gap: 1rem;
      align-items: flex-start;

      .news-feature__item__link {
        align-self: flex-end;
      }
    }
  }
}
