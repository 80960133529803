////
/// @group atoms
/// Icon list styles.
////

.list-icons {
  @include list-reset;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .list-icons__item {
    display: flex;
    gap: 1rem;
    align-items: center;
    @include secondary-typeface(small-text);
    margin: 0;

    span,
    svg {
      display: block;
    }

    svg {
      color: $red;
    }
  }

  &.list-icons--top {
    .list-icons__item {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  }

  &.list-icons--inverse {
    .list-icons__item {
      svg {
        color: $blue-light;
      }
    }

    a.list-icons__item {
      @include link-on-background;
    }
  }
}
