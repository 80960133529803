////
/// @group global
/// Styles neede to format content created in the WYSIWYG editor.
////

.intro-text {
  @include secondary-typeface(intro-text);

  p {
    @include secondary-typeface(intro-text);
  }
}

img.align-right,
.align-right,
.obj-right {
  display: block;
  @include align-center;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint(medium) {
    @include align-right;
    max-width: rem-calc(360px);
  }
}

img.align-left,
.align-left,
.obj-left {
  display: block;
  @include align-center;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoint(medium) {
    @include align-left;
    max-width: rem-calc(360px);
  }
}

img.align-center,
.align-center,
.obj-center {
  display: block;
  @include align-center;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

[data-embed-button='ohou_media_embed'] {
  .video-embed-field-responsive-video,
  .responsive-video-iframe {
    width: 100%;
  }
}

/// Figures with Captions (We disable Lint because selector chaining like "HTML.ClassName" is not valid)
// stylelint-disable

article.align-center {
  img {
    margin-right: auto;
    margin-left: auto;
  }
}

.btn--centered {
  margin-left: 50%;
  transform: translateX(-50%);
}

figure {
  margin: 0;
  padding: 0;
}

// stylelint-enable
figcaption {
  @include secondary-typeface(caption);
  margin-top: 0.5em;
  caption-side: bottom;
  color: inherit;
}

/// @type Spacing for Text Items
/// ---------
/// Text Scope
$wysiwyg-item-scope: 'p, ul, ol, hr, .section--wysiwyg li, .cke_editable li, .intro-text, blockquote, .accordions, .btn, h1, h2, h3, h4, h5, h6, table';

/// Apply margins globally to all elements within a WYSIWYG
.wysiwyg {
  overflow: hidden;

  #{$wysiwyg-item-scope} {
    @include global-spacing(body, margin-top);
    margin-bottom: 0;
  }

  .accordion + .accordion {
    @include global-spacing(body, margin-top);
  }

  figure {
    @include global-spacing(body, margin-top);
  }

  // Make sure headings clear
  h2,
  h3,
  h4,
  h5,
  h6 {
    clear: both;
  }

  /// Items with default top spacing
  h3,
  h4,
  h5,
  h6,
  blockquote {
    @include global-spacing(default, margin-top);
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    & + p {
      margin-top: 0.5rem;
    }
  }

  ol{
    li{
      &::marker{
        font-weight: 700;
      }
    }
  }

}

.mobile-table {
  @include global-spacing(small, margin-top);
  padding: 0;

  table {
    margin: 0;
  }
}

/// ---- setting all items top margins to 0 if they're first
/// Start with specifics

.card,
section,
header,
.listing-item,
.section--wysiwyg,
.wysiwyg {
  #{$wysiwyg-item-scope} {
    &:first-child {
      margin-top: 0;
    }
  }
}
