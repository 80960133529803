.acad-path {
  background: $red;
  color: $white;
  padding: 1rem 0;

  a:not(.btn) {
    @include link-on-background($bg: 'red');
  }

  p {
    margin: 0;
  }

  .acad-path__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .acad-path__m {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1.25rem 0;
    align-items: flex-start;
  }

  .acad-path__b {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .acad-path__heading {
    @include typeface(h2);
    color: inherit;
    text-transform: uppercase;
    margin: 0;
  }

  .acad-path__item {
    position: relative;
    width: 100%;
    height: 288px;
  }

  .acad-path__item__heading {
    @include typeface(h3);
    color: inherit;
    text-transform: uppercase;
    margin: 0;
  }

  .acad-path__item__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #012169 0%, rgba(1, 33, 105, 0) 60%);
      mix-blend-mode: multiply;
    }
  }

  .acad-path__item__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    padding: 0 1rem 1.5rem;
    z-index: 1;
  }

  .acad-path__grid {
    .acad-path__item__button {
      @include button(
        $style: 'inverse-secondary',
        $init-defaults: true,
        $hover-bg-color: $red,
        $hover-color: $white
      );
    }
  }

  @include breakpoint(large) {
    padding: 2.5rem 0;

    .acad-path__container {
      display: grid;
      grid-template-columns: 1fr 60%;
      gap: 0;
    }

    .acad-path__m {
      justify-content: center;
      gap: 1.5rem;
      padding: 0 2.5rem;
    }

    .acad-path__grid {
      display: flex;
      flex-wrap: wrap;
    }

    .acad-path__item {
      flex-basis: 50%;
      height: 380px;
    }

    .acad-path__item--large {
      flex-basis: 100%;
      height: 428px;
    }

    .acad-path__item__content {
      gap: 1rem;
      padding: 0 2rem 2.5rem;
    }
  }

  @include breakpoint(wide) {
    .acad-path__m {
      padding-left: $global-width-wide-diff;
    }
  }
}
