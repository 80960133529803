.gallery-item {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .gallery-item__media {
    img {
      @include notch-large($border: false);
      display: block;
      margin: 0;
    }
  }

  .gallery-item__content,
  .gallery-item__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .gallery-item__content {
    font-style: normal;
  }

  .gallery-item__caption {
    @include typeface(body);
    margin: 0;
  }

  .gallery-item__heading {
    @include typeface(h5-caps);
    margin: 0;
    color: $white;
  }

  @include breakpoint(large) {
    gap: 2.5rem;

    .gallery-item__content {
      gap: 1rem;
    }
  }
}

.gallery .swiper-container {
  &:not(.swiper-pre) {
    // Display gallery in a grid if slider is not initiated.
    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 2.5rem;
    }
  }
}

.gallery-section {
  padding: 2.5rem 0;
  background: $blue-dark;
  color: $white;

  a:not(.btn) {
    @include link-on-background;
    padding: 0;
  }

  .gallery-section__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  @include breakpoint(large) {
    padding: 6rem 0;

    .gallery-section__container {
      gap: 2.5rem;
    }
  }
}
