.callout {
  $callout-padding: 1.5rem;
  $callout-gap: 1rem;

  width: 100%;
  background: $blue-dark;
  color: $white;
  padding: $callout-padding;
  @include notch($size: 24px);

  p {
    margin: 0;
  }

  ul,
  ol {
    @include list-reset;
  }

  .callout__button {
    @include button($style: 'inverse-secondary', $init-defaults: true);
  }

  .callout__stat {
    @include secondary-typeface(stat);
    display: block;
  }

  .callout__img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0;
  }

  .callout__heading {
    @include typeface(h4-caps);
    color: inherit;
    margin: 0;
  }

  .callout__content {
    display: flex;
    flex-direction: column;
    gap: $callout-gap;
    align-items: flex-start;
  }

  .callout__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &.callout--stat {
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    gap: $callout-gap;

    .callout__heading {
      padding-bottom: $callout-gap;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 60px;
        height: 2px;
        background: $blue-light;
      }
    }
  }

  &.callout--img {
    padding: 0;

    .callout__content {
      padding: $callout-padding;
    }
  }

  @include breakpoint(large) {
    $callout-padding: 2.5rem;
    $callout-gap: 1.5rem;

    padding: $callout-padding;

    .callout__content {
      gap: $callout-gap;
    }

    &.callout--stat {
      gap: $callout-gap;

      .callout__heading {
        padding-bottom: $callout-gap;
      }
    }

    &.callout--img {
      padding: 0;

      .callout__content {
        padding: $callout-padding;
      }
    }
  }

  &.callout--diff {
    background: $white;
    color: $text-color;
    @include notch-border($size: 24px, $color: $blue-x-dark);

    .callout__button {
      @include button($style: null, $init-defaults: true);
    }

    .callout__heading {
      color: $blue-x-dark;
    }
  }

  &.callout--info {
    background: $blue-x-light;
    @include secondary-typeface(small-text);
    color: $text-color;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
      @include secondary-typeface(small-text-link);
    }

    .callout__heading {
      @include typeface(h5-caps);
      margin: 0;
    }

    .callout__info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .callout__info__item {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .callout__info__item__heading {
        @include secondary-typeface(small-text-bold);
        margin: 0;
        color: $black;
      }

      a {
        display: block;
      }

      ul,
      ol {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }
    }

    @include breakpoint(large) {
      gap: 1.5rem;

      .callout__info {
        gap: 1.5rem;
      }
    }
  }
}
