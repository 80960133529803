////
/// @group utilites
/// Functions and mixins for form elements.
////

/// Generates placeholder selectors for all major browsers.
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    // Gecko adds an opacity by default. Remove it.
    opacity: 1;
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin add-outline {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

@mixin input-padding {
  padding: 1rem 1.5rem;

  @include breakpoint(large) {
    padding: 1.5rem;
  }
}

@mixin remove-decoration {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
    appearance: none;
  }
}

@mixin checkmark($size: 1em) {
  &:checked {
    background-position: center center;
    background-size: $size;
    background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 32 24" fill="%23BA0C2F" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m10.869 23.463-10.4-10.4a1.6 1.6 0 0 1 0-2.263L2.73 8.537a1.6 1.6 0 0 1 2.263 0L12 15.543 27.006.537a1.6 1.6 0 0 1 2.263 0L31.53 2.8a1.6 1.6 0 0 1 0 2.263l-18.4 18.4a1.6 1.6 0 0 1-2.262 0Z"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
  }
}
