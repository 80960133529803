////
/// @group utilites
/// Functions and mixins for type.
////

/// Generate type styles for an element or a list of elements.
///
/// @param {Keyword|List} $elements - A list or single text element to print
///   selectors and styles for.
/// @param {Boolean} $print-class - Print a corresponding class for each
///   selector.
@mixin typefaces($elements, $print-class: false) {
  @each $element in $elements {
    $selector: #{$element};

    @if $print-class {
      $selector: '#{$selector}'+', .#{$element}';
    }

    #{$selector} {
      @include typeface($element);
    }
  }
}

/// Generate a type style for a single element.
///
/// @param {String} $element - The element to print text styles for.
@mixin typeface($element, $breakpoint: null) {
  @each $e-breakpoint, $properties in map-get($type-styles, $element) {
    @if $breakpoint {
      @if $breakpoint == $e-breakpoint {
        @each $property, $value in $properties {
          #{$property}: #{$value};
        }
      }
    } @else {
      @include breakpoint($e-breakpoint) {
        @each $property, $value in $properties {
          #{$property}: #{$value};
        }
      }
    }
  }
}

/// Generate a secondary type style.
///
/// @param {String} $style - The type of style to print.
@mixin secondary-typeface($style, $breakpoint: null) {
  @each $s-breakpoint, $properties in map-get($secondary-type-styles, $style) {
    @if $breakpoint {
      @if $breakpoint == $s-breakpoint {
        @each $property, $value in $properties {
          #{$property}: #{$value};
        }
      }
    } @else {
      @include breakpoint($s-breakpoint) {
        @each $property, $value in $properties {
          #{$property}: #{$value};
        }
      }
    }
  }
}

/// Generate a type style for a single element at a single breakpoint.
///
/// @param {String} $element - The element to print text styles for.
/// @param {String} $breakpoint - The breakpoint to generate rules for.
@mixin typeface-rules($element, $breakpoint) {
  $el: map-get($type-styles, $element);
  $properties: map-get($el, $breakpoint);
  @each $property, $value in $properties {
    #{$property}: #{$value};
  }
}

@mixin typeface-small-text($breakpoint: null) {
  @include secondary-typeface(small-text, $breakpoint);
}

@mixin typeface-small-text-link($breakpoint: null) {
  @include secondary-typeface(small-text-link, $breakpoint);
}

@mixin button-reset {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  text-align: inherit;
}

@mixin list-reset($type: 'unordered') {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;

  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    @if 'ordered' == $type {
      position: static;
      counter-increment: none;

      &:before {
        display: none;
      }
    }
  }
}

@function get-text-dec-color($bg: 'blue') {
  @if $bg == 'red' {
    @return $white;
  }
  @return $blue-light;
}

@mixin reset-text-decoration-only-hover(
  $color: $text-underline-color,
  $thickness: $text-dec-width-hover
) {
  text-decoration: none;

  &:hover,
  &:focus {
    @include text-decoration($color: $color, $thickness: $thickness);
  }
}

@mixin link-on-background($bg: 'blue', $only-active: false) {
  color: $white;

  @if $only-active == true {
    @include reset-text-decoration-only-hover($color: get-text-dec-color($bg));
  } @else {
    text-decoration-color: get-text-dec-color($bg);
  }
}

@mixin link-underline-offset($offset: $text-underline-offset) {
  text-underline-offset: $offset;
  padding-bottom: $offset - 1px;
}

@mixin text-decoration(
  $color: $text-underline-color,
  $thickness: $text-dec-width-default
) {
  text-decoration: underline;
  text-decoration-color: $color;
  text-decoration-thickness: $thickness;
  @include link-underline-offset;
}

@mixin reset-text-decoration($color: $text-underline-color) {
  @include text-decoration($color: $color);

  &:hover,
  &:focus {
    text-decoration-thickness: $text-dec-width-hover;
  }
}
