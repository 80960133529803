////
/// @group utilites
/// Functions and mixins for Drupal-specific elements.
////

/// Generates left float rules for objects in WYSIWYGs.
@mixin align-left {
  float: none;
  margin: rem-calc(24px 0);

  @include breakpoint(medium) {
    float: left;
    margin: rem-calc(30px 30px 30px 0);
  }
}

/// Generates right float rules for objects in WYSIWYGs.
@mixin align-right {
  float: none;
  margin: rem-calc(24px 0);

  @include breakpoint(medium) {
    float: right;
    margin: rem-calc(30px 0 30px 30px);
  }
}

/// Generates centering rules for objects in WYSIWYGs.
@mixin align-center {
  margin: rem-calc(24px) auto;
}

/// Generates rules for only showing items to a screen reader.
@mixin show-for-sr {
  position: absolute !important; // stylelint-disable-line
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
}

@mixin contain-silhouette-bg {
  position: relative;
  z-index: 0;
}

@mixin silhouette-bg(
  $defaults: true,
  $color: 'blue',
  $width: 100%,
  $height: 100%
) {
  @if $defaults == true {
    position: absolute;
    left: 0;
    top: 0;
    width: $width;
    height: $height;
    z-index: -1;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      mix-blend-mode: multiply;
    }
  }

  &:before {
    @if ($color == 'red') {
      background: $red;
    } @else if ($color == 'blue-light') {
      background: $blue-x-light;
    } @else {
      background: $blue-x-dark;
    }
  }

  &:after {
    @if ($color == 'red') {
      background: $red;
    } @else if ($color == 'blue-light') {
      background: $blue-x-light;
    } @else {
      background: $blue-dark;
    }
  }
}

@mixin silhouette-bg-img(
  $defaults: true,
  $color: 'blue',
  $position: center center,
  $image: null
) {
  @if $defaults == true {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-position: $position;
    background-repeat: no-repeat;
    background-size: cover;
    filter: saturate(0);
  }

  @if $image {
    background-image: url($image);
  }

  @if ($color == 'red') {
    opacity: 0.5;
  } @else if ($color == 'blue-light') {
    opacity: 0.1;
  } @else {
    opacity: 0.5;
  }
}

@mixin arrow-menu-arrow(
  $background: $white,
  $color: $red,
  $border_color: $red,
  $notch_top_right: false
) {
  @include button-reset;
  @if $notch_top_right == true {
    @include notch-top-right($size: 13px);
  } @else {
    @include notch(13px);
  }
  width: rem-calc(42);
  height: rem-calc(42);
  padding: 14px 13px 12px;
  background: $background;
  color: $color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover,
  &:focus {
    @if $notch_top_right == true {
      @include notch-border(
        $size: 13px,
        $color: $border_color,
        $width: 2px,
        $bottom_notch: false
      );
    } @else {
      @include notch-border($size: 13px, $color: $border_color, $width: 2px);
    }
  }
}

@mixin arrow-menu-arrow-open($background: $red, $color: $white, $border_color: $white) {
  background: $background;
  color: $color;
  @include notch-top-right($size: 13px);

  svg {
    transform: rotate(180deg);
  }

  &:hover,
  &:focus {
    @include notch-border(
      $size: 13px,
      $color: $border_color,
      $width: 2px,
      $bottom_notch: false
    );
  }
}

@mixin arrow-menu-text($caps: false) {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  flex-grow: 1;
  @if $caps == true {
    @include typeface(h6-caps);
  } @else {
    @include typeface(h6);
  }
  text-align: left;
  color: $white;
  text-decoration-color: $white;
}

@mixin arrow-menu-wrapper {
  @include button-reset;
  display: flex;
  width: 100%;
  text-align: left;
  @include typeface-small-text-link(large);
  color: $white;
}

@mixin arrow-menu-container {
  display: flex;
  flex-direction: column;
}

@mixin arrow-menu-menu {
  @include list-reset;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: $red;
  color: $white;
  padding: 1.5rem;

  a {
    @include link-on-background($bg: 'red', $only-active: true);
    display: block;
    line-height: 150%;
    color: $white;
    text-decoration-color: $white;
  }
}

@mixin arrow-menu-nav {
  @include list-reset;
  background: $red;
  color: $white;
  padding: 1.5rem;

  span {
    display: block;
  }

  a {
    @include link-on-background($bg: 'red', $only-active: true);
    display: block;
    line-height: 150%;
    color: $white;
    text-decoration-color: $white;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
}
