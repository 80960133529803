////
/// @group components
/// Styles for tags lists.
////

ol.tags,
ul.tags {
  @include list-reset;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  li {
    @include secondary-typeface(small-text);
  }

  @include breakpoint(medium) {
    flex-direction: row;
    gap: 1.5rem;
  }

  &.tags--caps {
    li {
      @include typeface(h6-caps);
    }
  }
}
