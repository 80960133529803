////
/// @group components
/// Styles for breadcrumbs.
////

@mixin breadcrumb-arrow($color: 'BA0C2F') {
  &:before {
    content: '';
    width: 0.5em;
    height: 0.6em;
    display: inline-block;
    margin: 0 0.5rem 0 0;
    background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 6 8" fill="%23#{$color}" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="m.668 4.425 3.407 3.399a.6.6 0 0 0 .849 0l.566-.565a.596.596 0 0 0 0-.847l-2.415-2.41L5.49 1.594a.596.596 0 0 0 0-.847l-.563-.57a.6.6 0 0 0-.85 0l-3.406 3.4a.597.597 0 0 0-.003.849Z"/%3E%3C/svg%3E');
    background-position: right center;
    background-repeat: no-repeat;
  }
}

@mixin breadcrumbs($color: 'red') {
  ol {
    @include list-reset($type: 'ordered');
  }

  a {
    display: inline-block;
    @include secondary-typeface(small-text-link);

    @if $color == 'blue-light' {
      color: $blue-light;
      text-decoration-color: $blue-light;
      @include breadcrumb-arrow($color: '59CBE8');
      @include reset-text-decoration-only-hover($color: $blue-light);
    } @else {
      @include breadcrumb-arrow;
      @include reset-text-decoration-only-hover;
    }
  }
}

/// Base Breadcrumbs
/// ----------------
nav.breadcrumbs {
  @include breadcrumbs;
}
