section.body-nav {
  padding: 1.5rem 0;

  .body_nav__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  @include breakpoint(medium down) {
    .body_nav__nav .accordion {
      display: flex;
      background: transparent;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  @include breakpoint(large) {
    padding: 4rem 0 5rem;

    .body_nav__container {
      display: grid;
      grid-template-columns: rem-calc(360px) 1fr;
      gap: 2.5rem;
    }
  }
}
