////
/// @group global
/// Base styles for form elements for any CMS.
////

/// Global Form Element Resets
/// --------------------------
/// Get all major rendering engines to the same starting point.

form {
  margin: 0;
  padding: 0;
}

// Text inputs and buttons.
#{text-inputs()},
[type='file'],
[type='image'],
button,
[type='submit'],
textarea,
select,
fieldset {
  display: block;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
}

select,
fieldset {
  width: 100%;
}

select {
  background-color: $white;
  background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 16 12" fill="%23BA0C2F" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7.155 10.664.353 3.85a1.199 1.199 0 0 1 0-1.699l1.13-1.132c.47-.47 1.23-.47 1.695 0L8 5.85l4.822-4.83c.47-.47 1.23-.47 1.695 0l1.13 1.132c.47.471.47 1.233 0 1.699l-6.802 6.813c-.46.47-1.22.47-1.69 0Z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: calc(100% - 1.5rem) center;
  background-size: 1em;
  @include typeface(h6);
  color: $red;
  @include notch;
  @include input-padding;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $blue-dark;
    background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 16 12" fill="%23FFFFFF" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7.155 10.664.353 3.85a1.199 1.199 0 0 1 0-1.699l1.13-1.132c.47-.47 1.23-.47 1.695 0L8 5.85l4.822-4.83c.47-.47 1.23-.47 1.695 0l1.13 1.132c.47.471.47 1.233 0 1.699l-6.802 6.813c-.46.47-1.22.47-1.69 0Z"/%3E%3C/svg%3E');
    color: $white;
  }
}

/// Buttons
/// -------
form button,
form [type='submit'] {
  cursor: pointer;
  @include button-reset;
  @include button($style: 'secondary', $init-defaults: true);
}

/// Text Inputs
/// -----------
#{text-inputs()},
[type='file'],
[type='image'],
textarea {
  @include typeface(body);

  @include placeholder {
    color: $gray;
  }
}

/// Checkboxes and Radios
/// ---------------------
[type='checkbox'],
[type='radio'] {
  margin: 0;
  cursor: pointer;
}

[type='checkbox'] {
  appearance: none;
  border: 2px solid $red;
  border-radius: 0;
  @include checkmark;
}

/// States
/// ------
#{text-inputs()},
[type='file'],
[type='image'],
select,
textarea {
  &:disabled {
    background-color: $gray--light;
  }
}

// Invalid styles, but not when the placeholder is present to avoid empty
// fields from presenting an error before user interaction.
#{text-inputs()},
[type='file'],
[type='image'],
textarea {
  &:not(:empty):invalid {
    border-color: $accent-red;
    box-shadow: 0 0 0 0.0625em $accent-red;
  }
}

@mixin mui-form-control-override() {
  display: block;
  background: transparent;
  width: auto;
  height: auto;
  max-width: 100%;
  min-width: 0;
  margin: 0;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
  color: inherit;
  position: static;
  left: auto;
  top: auto;
  transform: none;
  transition: none;
  text-overflow: unset;
  white-space: unset;
  pointer-events: unset;
}

.MuiFormControl-root,
.MuiFormControl-fullWidth,
.MuiFormControl-root.MuiFormControl-fullWidth,
.MuiAutocomplete-root,
.MuiOutlinedInput-root,
.MuiAutocomplete-input {
  @include mui-form-control-override;
}

/// Labels
/// ------
label,
label.MuiInputLabel-formControl,
label.MuiInputLabel-outlined,
label.MuiFormLabel-root,
label.MuiFormLabel-colorPrimary,
legend {
  @include mui-form-control-override;
  display: block;
  width: 100%;
  @include typeface(h6-desktop);
  @include typeface(h6-color);
  margin: 0;
}

.oho-rfl--autocomplete__has-clear-icon {
  .oho-rfl--autocomplete__input-root {
    background: $white;
    @include notch;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 100px 0.5rem 12px !important;

    fieldset {
      display: none;
    }

    .oho-rfl--autocomplete__input,
    .oho-rfl--autocomplete__input--focused,
    .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
      display: block;
      flex: 1;
      width: auto;
      height: auto;
      color: $text-color;
      min-height: 34px; /* height of submit button minus input padding */
      min-width: unset;
      animation: none;
      border: 0;
      padding: 0.5rem;
      margin: 0;
      @include typeface(body);
      @include remove-decoration;

      @include placeholder {
        color: $gray;
      }

      &:focus {
        @include add-outline;
      }

      @include breakpoint(medium) {
        min-height: 38px; /* height of submit button minus input padding */
      }
    }

    .oho-rfl--autocomplete__endadornment {
      position: static;
      margin: 0;
      padding: 0;
      border: 0;

      &:hover,
      &:focus {
        margin: 0;
      }
      &:focus {
        @include add-outline;
      }
    }

    .oho-rfl--autocomplete__clear-indicator {
      margin: 0;
      &:focus {
        @include add-outline;
      }
    }

    @include breakpoint(large) {
      padding: 10px 100px 10px 22px !important;
    }
  }
}

form {
  .form__field {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
  }

  .form__fieldset {
    display: flex;

    fieldset {
      display: flex;
    }

    // Legends dont work well with flex
    legend {
      margin-bottom: 0.5rem;
    }
  }

  .form__fieldset__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    color: $red;
    @include notch;

    .form__field {
      border-top: 1px solid $red-light-opacity-half;

      &:first-child {
        border-top: 0;
      }
    }
  }

  .form__field--searchAuto {
    position: relative;

    .MuiAutocomplete-root {
      width: 100%;
    }

    .MuiFormControl-root {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }

	button,
    button[type='submit'] {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
    }
    @include breakpoint(large) {
		button,
		button[type='submit'] {
        bottom: 0.65rem;
      }
    }
  }

  .form__field--checkbox {
    background: $white;
    align-items: center;
    flex-direction: row;
    @include input-padding;

    label {
      display: flex;
      gap: 1rem;
      align-items: center;

      span {
        flex: 1;
        @include typeface(h3);
        color: $red;
        margin: 0;
      }

      input[type='checkbox'] {
        width: 2.5rem;
        height: 2.5rem;
        transform: scale(1, 1);
        @include checkmark($size: 2rem);
      }
    }
  }

  @include breakpoint(large) {
    .form__field:not(.form__field--secondary),
    .form__fieldset:not(.form__field--secondary) {
      label,
      legend {
        @include typeface(h5);
        @include typeface(h5-color);
        margin: 0;
      }
    }

    .form__field:not(.form__field--secondary) {
      gap: 1rem;
    }

    .form__fieldset:not(.form__field--secondary) {
      // Legends dont work wellwith flex
      legend {
        margin-bottom: 1rem;
      }
    }
  }

  &.form--inverse {
    color: $white;

    label,
    legend {
      color: $white !important;
    }
  }
}
