.tabbed {
  padding: 2.5rem 0;
  color: $black;

  .tabbed__c {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .tabbed__heading {
    @include typeface(h2-caps);
    margin: 0;
  }

  .process__heading {
    @include typeface(h3);
    margin: 0 0 0.5rem;
  }

  .tabbed__stat {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem 0 0;

    .tabbed__stat__value {
      @include secondary-typeface(stat);
    }

    .tabbed__stat__desc {
      @include typeface(h6-caps);
      color: inherit;
    }
  }

  &.component.component--inverse {
    .accordion {
      background: transparent;

      .accordion__content {
        background: transparent;
      }

      .accordion__content,
      .accordion__content * {
        color: $white;
      }
    }
  }

  &.connection {
    color: $white;

    .silhouette__bg__img {
      @include silhouette-bg-img(
        $defaults: true,
        $color: 'red',
        $position: center top
      );
    }

    a:not(.btn) {
      @include link-on-background($bg: 'red');
    }
  }

  @include breakpoint(medium down) {
    .accordions {
      @include stack-with-notch;

      .accordion {
        @include notch-remove;

        .accordion__button .accordion__button__inner {
          border: 0 !important;
          padding: 0 !important;
        }

        .accordion__content {
          padding: 1.5rem;
        }
      }
    }

    &.component.component--inverse .accordions {
      @include stack-with-notch($inverse: true);
    }
  }

  @include breakpoint(large) {
    padding: 6rem 0;

    .accordions {
      clip-path: unset;
    }

    .tabbed__c {
      gap: 2.5rem;
    }

    .tabbed__stat {
      flex-direction: row;
      gap: 2.5rem;
      align-items: center;
      margin-top: 2.5rem;

      .tabbed__stat__desc {
        @include typeface(h4-caps);
        color: inherit;
      }
    }
  }

  &.component.component--inverse {
    .tabbed__heading {
      color: $white;
    }
  }
}
