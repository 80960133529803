// Will help create a notch "stack".
// Used on accordions and tabs.

@mixin stack-with-notch($inverse: false) {

  @if $inverse == true {
    @include notch($size: 24px);
    @include notch-border($size: 24px);
  } @else {
    @include notch($size: 24px);
    @include notch-border($size: 24px, $color: $red);
  }

  button[aria-controls] {
    @include typeface(h6-caps);
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 1rem 1.5rem;
    transition: background-color $primary-transition, color $primary-transition;
    text-align: left;
    background: transparent;

    @if $inverse == true {
      color: $white;
      border-top: 1px solid $white-opacity;

      &:hover,
      &:focus,
      &[aria-selected='true'],
      &[aria-expanded='true'] {
        background: $white;
        color: $red;
      }
    } @else {
      color: $red;
      border-top: 1px solid $red-light-opacity-half;

      &:hover,
      &:focus,
      &[aria-selected='true'],
      &[aria-expanded='true'] {
        background: $red;
        color: $white;
      }
    }
  }
}
