.content-callout {
  color: $text-color;

  .content-callout__container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .content-callout__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .content-callout__intro {
    @include secondary-typeface(intro-text);
    margin: 0;
  }

  .content-callout__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  h1.content-callout__heading {
    @include typeface(h1);
    margin: 0;
  }

  h2.content-callout__heading {
    @include typeface(h2);
    margin: 0;
  }

  .content-callout__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .content-callout__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .content-callout__aside {
    width: 100%;
  }

  @include breakpoint(large) {
    .content-callout__container {
      gap: 1rem;
    }

    .content-callout__main {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 2.5rem;
    }

    .content-callout__content,
    .content-callout__body {
      gap: 1.5rem;
    }

    .content-callout__aside {
      flex: 0 0 rem-calc(360px);
    }
  }
}

section.content-callout-section {
  padding: 2.5rem 0;

  @include breakpoint(large) {
    padding: 6rem 0;
  }

  &.content-callout-info {
    padding: 1rem 0 2.5rem;

    @include breakpoint(large) {
      padding: 4rem 0 6rem;
    }

    &.content-callout-info--program {
      .content-callout__body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    &.content-callout-info--profile {
      .content-callout__profile {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
      }

      .content-callout__profile__item {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      h2.content-callout__profile__heading {
        @include typeface(h3);
        margin: 0;
      }

      .content-callout__profile__accordions {
        background: $blue-x-light;
        @include notch;

        .accordion {
          background: $blue-x-light;
          @include notch-remove;
        }
      }
    }
  }

  &.body-area {
    padding: 4rem 0;
  }
}
