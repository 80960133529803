////
/// @group variables
/// Variables needed to compile foundation grid.
////

///  Table of Contents:
///
///   1. Global
///   2. Breakpoints
///  56. Xy Grid

@import 'util/util';

/// 1. Global
/// ---------
// The global width should match the value of the 'full' key in the $breakpoints
// array in section two below.
$global-width: rem-calc(1240px);
$global-width-wide: rem-calc(1440px);
$global-width-wide-diff: calc((1440px - 1240px)/2);
$foundation-palette: (
  primary: $primary-color,
  secondary: #767676,
  success: $message-success,
  warning: #ffae00,
  alert: $message-alert,
);
$light-gray: $gray--light;
$medium-gray: $gray;
$dark-gray: $gray--dark;
$black: $black;
$white: $white;

/// 2. Breakpoints
/// --------------
$breakpoints: (
  initial: 0,
  tiny: 321px,
  small: 641px,
  medium: 769px,
  large: 1025px,
  full: 1240px,
  wide: 1360px, // Is used for wide containers
  xlarge: 1441px,
);
$print-breakpoint: large;
$breakpoint-classes: (initial tiny small medium large);

/// 56. Xy Grid
/// -----------
$xy-grid: true;
$grid-container: $global-width;
$grid-columns: 12;
$grid-margin-gutters: (
  initial: 24px,
  medium: 40px
);
$grid-padding-gutters: $grid-margin-gutters;
$grid-container-padding: (
  initial: 48px,
  medium: 80px
);
$grid-container-max: $global-width;
$xy-block-grid-max: 8;
