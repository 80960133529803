.active-filters {
  width: 100%;

  .active-filters__heading {
    @include typeface(body);
    color: $text-color;
    font-weight: 700;
    margin: 0;
  }

  .active-filters__list {
    @include list-reset;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;

    @include breakpoint(large) {
      flex-direction: row;
      column-gap: 2.5rem;
      row-gap: 1rem;
    }
  }
}
