////
/// @group components
/// Styles for Slider globals
////

/// Hide navigation if swiper is not initialized
.slider-navigation {
  display: none;
  pointer-events: none;

  .swiper-container-initialized & {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: calc(100% + (2 * 1.5rem));
    height: auto;
    left: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;

    button {
      @include button-reset;
      pointer-events: auto;
    }

    svg {
      font-size: 2.5rem;
      line-height: 1;
      color: $red;
    }

    &.swiper-color-white svg {
      color: $white;
    }

    &.swiper-color-blue svg {
      color: $blue-light;
    }

    .swiper-button-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .slider-navigation__prev svg {
      transform: rotate(180deg);
    }

    @include breakpoint(medium) {
      width: calc(100% + (2 * 2.5rem));
      left: -2.5rem;
    }

    @include breakpoint(wide) {
      left: -5%;
      width: 110%;
    }
  }
}

.swiper-container {
  width: 100%;
  overflow: visible;
  position: relative;

  .swiper-slide {
    height: auto;
  }

  // Wrap items and provide gap if slider is not initiated.
  &.swiper-slides-visible:not(.swiper-container-initialized) {
    .swiper-wrapper {
      flex-wrap: wrap;
      gap: 1rem;

      @include breakpoint(large) {
        gap: 2.5rem;
      }
    }
  }

  &.swiper-container-initialized {
    .swiper-wrapper {
      z-index: 1;
    }

    .swiper-slide {
      transition: opacity $primary-transition;
      opacity: 0;

      &.swiper-slide-visible {
        opacity: 1;
      }
    }

    &.swiper-slides-visible {
      .swiper-slide {
        &:not(.swiper-slide-visible) {
          opacity: 0.25;
        }

        // Trying to hide items before the visible items.
		&:nth-child(-n+3):not(.swiper-slide-visible):not(.swiper-slide-prev):not(.swiper-slide-next) {
          opacity: 0;
        }
      }
    }
  }
}
