////
/// @group components
/// Styles for tabs.
////

.tabs {
  @include breakpoint(medium down) {
    // Set the buttons to not display even though we remove them from the DOM,
    // to avoid a flash before the JavaScript removes them.
    .tabs__buttons {
      display: none;
    }
  }

  @include breakpoint(large) {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 2.5rem;
    align-items: flex-start;
    padding: 2.5rem 0;
    border-top: 2px solid $red-light-opacity-half;
    border-bottom: 2px solid $red-light-opacity-half;

    .component.component--inverse & {
      border-color: $white-opacity;
    }

    // When JavaScript doesn't load for some reason, take content full width.
    :root:not(.oho-js) & {
      display: block;
    }

    .tabs__buttons {
      @include stack-with-notch;

      .component.component--inverse & {
        @include stack-with-notch($inverse: true);
      }

      // When JavaScript doesn't load for some reason, hide the tab buttons.
      :root:not(.oho-js) & {
        display: none;
      }
    }

    .accordion {
      @include clearfix;
      @include notch-remove;
      display: block;
      margin: 0;
      padding: 0;

      &[aria-hidden='true'] {
        display: none;
      }

      // Set the button to not display even though we remove it from the DOM, to
      // avoid a flash before the JavaScript removes it.
      .accordion__button {
        display: none;
      }

      // When JavaScript doesn't load for some reason, show the button.
      :root:not(.oho-js) & {
        .accordion__button {
          display: block;
          background: transparent;
          border: 1px solid $white;
        }

        .accordion__content {
          padding: 2.5rem 0;
        }
      }
    }
  }
}
