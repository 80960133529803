////
/// @group global
/// Base styles for common elements.
////

/// Elements Base Styles
/// --------------------
:root {
  font-size: 16px;
}

* {
  // stylelint-disable-line
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  color: $black;
  font-family: $font-body;
  font-size: 1rem; /* 16px */
  font-weight: 400;
  font-style: normal;
  line-height: 1.5rem; /* 24px */
  -webkit-text-size-adjust: 100%; /// @todo Is this still needed?
}

body {
  @include typeface(body);
  margin: 0;
  color: $text-color;
}

// IE 11 missing UA styles fix.
main {
  display: block;
  background: $white;
}

/// Make Skip links visible when focus
.skip-actions {
  a,
  button {
    &:focus {
      @include typeface(h6);
      position: absolute !important;
      z-index: 999;
      display: block;
      overflow: visible;
      clip: unset;
      width: auto;
      height: auto;
      padding: 1rem;
      text-align: center;
      color: $white;
      border: 0;
      background: $red;
    }
  }
  a:focus {
    text-decoration: underline;
    text-decoration-color: $white;
  }
}

button {
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  font-family: inherit;
  font-size: 1rem;
}

iframe {
  max-width: 100%;
  border: 0;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  display: block;
  margin: 0;
}

/// Margin and padding resets
/// -------------------------
dl,
pre {
  margin: 0;
  padding: 0;
}

/// Helper Classes
/// -------
.clearfix {
  @include clearfix;
}

.show-for-sr,
.visually-hidden {
  @include show-for-sr;
}

nav.list-reset ul,
nav.list-reset ol,
ul.list-reset,
ol.list-reset {
  @include list-reset;
}

nav.list-buttons {
  ol {
    @include list-reset;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &.list-buttons--md {
    @include breakpoint(medium) {
      ol {
        flex-direction: row;
        gap: 1rem;
      }
    }
  }

  @include breakpoint(large) {
    ol {
      flex-direction: row;
      gap: 1rem;
    }
  }
}

.grid-container {
  box-sizing: border-box;
  width: 100%;
}

.grid-container-wide {
  @include xy-grid-container(
    $width: $global-width-wide,
    $padding: rem-calc(32px)
  );
  box-sizing: border-box;

  @include breakpoint(wide) {
    @include xy-gutters(
      $gutters: $grid-container-padding,
      $gutter-type: padding
    );
  }
}

.components {
  .component {
    overflow-x: hidden;
  }

  .component.component--transp + .component.component--transp {
    padding-top: 0;
  }
}

.silhouette {
  @include contain-silhouette-bg;

  .silhouette__bg {
    @include silhouette-bg($color: 'blue');
  }

  .silhouette__bg__img {
    @include silhouette-bg-img($color: 'blue');
  }

  &.silhouette--red {
    .silhouette__bg {
      @include silhouette-bg($defaults: false, $color: 'red');
    }

    .silhouette__bg__img {
      @include silhouette-bg-img($defaults: false, $color: 'red');
    }
  }

  &.silhouette--zoom {
    overflow: hidden;

    .silhouette__bg__img {
      transition: width $primary-transition, height $primary-transition;
    }
    &:hover,
    &:focus-within {
      .silhouette__bg__img {
        width: 120%;
        height: 120%;
      }
    }
  }
}

@include breakpoint(medium down) {
  .arrow-menu-mobile {
    @include arrow-menu-container;

    .arrow-menu-mobile__button {
      @include arrow-menu-wrapper;
    }

    .arrow-menu-mobile__text {
      @include arrow-menu-text;
    }

    .arrow-menu-mobile__icon {
      @include arrow-menu-arrow;
    }

    .arrow-menu-mobile__nav {
      @include arrow-menu-nav;
      display: none;
    }

    &.arrow-menu-mobile--open {
      .arrow-menu-mobile__icon {
        @include arrow-menu-arrow-open;
      }
      .arrow-menu-mobile__nav {
        display: block;
      }
    }
  }
}
