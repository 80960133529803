.small-img-text {
  padding: 2.5rem 0;

  img {
    display: block;
  }

  p {
    margin: 0;
  }

  .small-img-text__items {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .small-img-text__i {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .small-img-text__i__img img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: 0;
  }

  .small-img-text__i__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .small-img-text__i__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .small-img-text__i__heading {
    @include typeface(h3);
    margin: 0;
  }

  @include breakpoint(small) {
    .small-img-text__items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 2.5rem;
    }
  }

  @include breakpoint(large) {
    padding: 6rem 0;

    .small-img-text__items {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 2.5rem;
    }

    .small-img-text__i {
      display: grid;
      grid-template-columns: 460px 1fr;
      gap: 2.5rem;
      align-items: center;
    }

    .small-img-text__i__content {
      gap: 1.5rem;
    }
  }
}
