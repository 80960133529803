////
/// @group variables
/// Site-wide typefaces.
////

/// Typeface Definitions
/// --------------------

/// Typefaces
/// @type String
$font-calibre: 'Calibre', sans-serif;
$font-leitura: 'LeituraNews-Roman2', serif;

$font-display: $font-calibre;
$font-body: $font-calibre;

$text-dec-width-default: 1px;
$text-dec-width-hover: 2px;
$text-underline-offset: 6px;
$text-underline-color: $red-light;

/// Type Styles
/// @type Map
$type-styles: (
  h1: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(40),
      line-height: 1,
      font-weight: 900,
      color: $blue-x-dark,
      text-transform: none,
    ),
    medium: (
      font-size: rem-calc(60),
    ),
    large: (
      font-size: rem-calc(80),
    ),
  ),
  h2: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(32),
      line-height: 1,
      font-weight: 900,
      color: $blue-x-dark,
      text-transform: none,
    ),
    medium: (
      font-size: rem-calc(40),
    ),
    large: (
      font-size: rem-calc(60),
    ),
  ),
  h2-caps: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(32),
      line-height: 1,
      font-weight: 900,
      color: $blue-x-dark,
      text-transform: uppercase,
    ),
    medium: (
      font-size: rem-calc(40),
    ),
    large: (
      font-size: rem-calc(60),
    ),
  ),
  h3: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(28),
      line-height: 1,
      font-weight: 700,
      color: $blue-x-dark,
      text-transform: none,
    ),
    medium: (
      font-size: rem-calc(32),
    ),
    large: (
      font-size: rem-calc(40),
    ),
  ),
  h3-caps: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(28),
      line-height: 1,
      font-weight: 700,
      color: $blue-x-dark,
      text-transform: uppercase,
    ),
    medium: (
      font-size: rem-calc(32),
    ),
    large: (
      font-size: rem-calc(40),
    ),
  ),
  h4: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(26),
      line-height: 1,
      font-weight: 700,
      color: $blue-x-dark,
      text-transform: none,
    ),
    medium: (
      font-size: rem-calc(28),
    ),
    large: (
      font-size: rem-calc(32),
    ),
  ),
  h4-caps: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(26),
      line-height: 1,
      font-weight: 700,
      color: $blue-x-dark,
      text-transform: uppercase,
    ),
    medium: (
      font-size: rem-calc(28),
    ),
    large: (
      font-size: rem-calc(32),
    ),
  ),
  h5: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(18),
      line-height: 1,
      font-weight: 700,
      text-transform: none,
    ),
    medium: (
      font-size: rem-calc(22),
    ),
    large: (
      font-size: rem-calc(26),
    ),
  ),
  h5-color: (
    initial: (
      color: $blue-x-dark,
    ),
  ),
  h5-caps: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(18),
      line-height: 1,
      font-weight: 700,
      color: $blue-x-dark,
      text-transform: uppercase,
    ),
    medium: (
      font-size: rem-calc(22),
    ),
    large: (
      font-size: rem-calc(26),
    ),
  ),
  h6: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(16),
      line-height: 1,
      font-weight: 700,
      text-transform: none,
    ),
    medium: (
      font-size: rem-calc(18),
    ),
    large: (
      font-size: rem-calc(22),
    ),
  ),
  h6-desktop: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(22),
      line-height: 1,
      font-weight: 700,
      text-transform: none,
    ),
  ),
  h6-caps: (
    initial: (
      font-family: $font-display,
      font-size: rem-calc(16),
      line-height: 1,
      font-weight: 700,
      text-transform: uppercase,
    ),
    medium: (
      font-size: rem-calc(18),
    ),
    large: (
      font-size: rem-calc(22),
    ),
  ),
  h6-color: (
    initial: (
      color: $blue-x-dark,
    ),
  ),
  body: (
    initial: (
      font-family: $font-body,
      font-size: rem-calc(16),
      line-height: 1.5,
      font-weight: 400,
    ),
    large: (
      font-size: rem-calc(18),
    ),
  ),
  body-bold: (
    initial: (
      font-family: $font-body,
      font-size: rem-calc(16),
      line-height: 1.5,
      font-weight: 700,
    ),
    large: (
      font-size: rem-calc(18),
    ),
  ),
  body-link: (
    initial: (
      line-height: 1.5,
      font-weight: 700,
      color: $link-color,
    ),
  ),
);

/// Custom text sizes.
/// @type Map
$secondary-type-styles: (
  caption: (
    initial: (
      font-family: $font-body,
      font-size: rem-calc(12),
      font-style: italic,
      line-height: 1.5,
      font-weight: 400,
    ),
    large: (
      font-size: rem-calc(14),
    ),
  ),
  blockquote: (
    initial: (
      font-family: $font-leitura,
      font-size: rem-calc(18),
      font-weight: 400,
      line-height: 1.75,
    ),
    large: (
      font-size: rem-calc(28),
    ),
  ),
  intro-text: (
    initial: (
      font-family: $font-leitura,
      font-size: rem-calc(18),
      font-weight: 400,
      line-height: 1.75,
    ),
    large: (
      font-size: rem-calc(28),
    ),
  ),
  small-text: (
    initial: (
      font-family: $font-body,
      font-size: rem-calc(14),
      line-height: 1.5,
      font-weight: 400,
    ),
    large: (
      font-family: $font-body,
      font-size: rem-calc(16),
      line-height: 1.5,
      font-weight: 400,
    ),
  ),
  small-text-bold: (
    initial: (
      font-family: $font-body,
      font-size: rem-calc(14),
      line-height: 1.5,
      font-weight: 700,
    ),
    large: (
      font-family: $font-body,
      font-size: rem-calc(16),
      line-height: 1.5,
      font-weight: 700,
    ),
  ),
  small-text-link: (
    initial: (
      font-family: $font-body,
      font-size: rem-calc(14),
      line-height: 1.5,
      font-weight: 700,
    ),
    large: (
      font-family: $font-body,
      font-size: rem-calc(16),
      line-height: 1.5,
      font-weight: 700,
    ),
  ),
  stat: (
    initial: (
      font-family: $font-body,
      font-size: rem-calc(64),
      line-height: 0.9,
      font-weight: 900,
    ),
    large: (
      font-size: rem-calc(80),
    ),
  ),
);
