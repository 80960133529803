////
/// @group menus
/// Styles for the accessible menu JavaScript.
////

.accessible-menu {

  .menu-item {

    ul.menu {
      display: none;
    }

    &.menu-item--open {

      ul.menu {
        display: flex;
      }
    }
  }

  // When JavaScript doesn't load for some reason, show all items.
  :root:not(.oho-js) & {
    &.accessible-menu-visible ul.menu {
      display: flex;
    }
  }
}
