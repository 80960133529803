////
/// @group components
/// Styles for the checkerboard.
////

.checkerboard {
  background: $red;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  color: $white;

  a:not(.btn) {
    @include link-on-background($bg: 'red');
  }

  figure,
  img {
    display: block;
    margin: 0;
  }

  .btn {
    align-self: flex-start;
  }

  .grid-container,
  .grid-container-wide {
    width: 100%;
  }

  .checkerboard__item__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 1rem;
    justify-content: center;

    p {
      margin: 0;
    }
  }

  .checkerboard__item__content__b {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .checkerboard__item__heading {
    @include typeface(h3);
    color: $white;
    margin: 0;
  }

  .checkerboard__links {
    @include list-reset;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    a {
      display: inline-block;
    }
  }

  .checkerboard__item__media {
    img {
      width: 100%;
    }

    figcaption {
      width: 100%;
      height: auto;
      padding: 0.5rem 1rem 0;
      background: $red;

      a {
        display: inline;
        font-size: inherit;
        font-style: normal;
        font-weight: 700;
      }
    }
  }

  .checkerboard__item__media__frame {
    position: relative;

    a.play-button {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  @include breakpoint(medium) {
    .checkerboard__item__media__frame {
      height: 442px;
    }
  }

  @include breakpoint(large) {
    gap: 2.5rem;
    padding-top: 6rem;
    padding-bottom: 2.5rem;

    .checkerboard__item {
      display: flex;

      &:nth-child(odd) {
        flex-direction: row-reverse;

        figcaption {
          left: auto;
          right: 0;
        }
      }

      // To accomodate the notch.
      &:nth-child(even):last-child {
        figcaption {
          padding-left: 2.5rem;
        }
      }
    }

    .checkerboard__item__media,
    .checkerboard__item__content {
      flex-basis: 50%;
    }

    .checkerboard__item__content {
      padding: 2.5rem;
    }

    .checkerboard__links {
      flex-direction: row;
      gap: 1.5rem;
    }

    .checkerboard__item__media {
      figure {
        position: relative;
        height: 100%;
      }

      figcaption {
        position: absolute;
        left: 0;
        bottom: 0;
        width: auto;
        padding: 1rem;
      }
    }

    .checkerboard__item__media__frame {
      height: 100%;
    }
  }

  @include breakpoint(wide) {
    .checkerboard__item {
      &:nth-child(odd) {
        .checkerboard__item__content {
          padding-left: $global-width-wide-diff;
        }
      }

      &:nth-child(even) {
        .checkerboard__item__content {
          padding-right: $global-width-wide-diff;
        }
      }
    }
  }
}
