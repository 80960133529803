.profiles-section {
  padding: 2.5rem 0;

  .profiles-section__c {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .profiles__wrapper {
    display: flex;
    overflow: visible;
  }

  .profiles__item {
    display: flex;
    width: rem-calc(260px);
    flex-direction: column;
    gap: 1rem;

    p {
      margin: 0;
    }
  }

  .profiles__item__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .profiles__item__heading {
    @include typeface(h5);
	@include typeface(h5-color);
    margin: 0;
  }

  .profiles__item__title {
    @include secondary-typeface(small-text);
  }

  .profiles__item__img {
    @include notch($size: 16px);
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
  }

  @include breakpoint(large) {
    padding: 6rem 0;

    .profiles-section__c {
      gap: 2.5rem;
    }

    .profiles__item {
      gap: 1.5rem;
    }

    .profiles__item__content {
      gap: 1rem;
    }
  }
}
