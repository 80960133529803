////
/// @group atoms
/// Styles for buttons.
/// .btn is the required class, it contains the default inits
////

.btn {
  @include button($style: null, $init-defaults: true);
}

.btn--inverse:not(.btn--secondary) {
  @include button($style: 'inverse', $init-defaults: false);
}

.btn--secondary:not(.btn--inverse) {
  @include button($style: 'secondary', $init-defaults: false);
}

.btn--inverse.btn--secondary {
  @include button($style: 'inverse-secondary', $init-defaults: false);
}

.btn--align-right {
  margin-left: auto;
}

.btn--align-center {
  margin-right: auto;
  margin-left: auto;
}

a[data-entity-type='media'],
a[href$='.doc'],
a[href$='.docx'],
a[href$='.pdf'],
a[href$='.ppt'],
a[href$='.pptx'],
a[href$='.xls'],
a[href$='.xlsx'],
a[href$='.zip'] {
  &:not(.btn) {
    @include button-download;
  }

  &.btn {
    @include button-download($color: 'fff');
  }

  &.btn--inverse {
    @include button-download;
  }
}

a.self-external,
a.link-external,
a.external {
  &:not(.btn) {
    @include button-external;
  }

  &.btn {
    @include button-external($color: 'fff');
  }

  &.btn--inverse {
    @include button-external($hover-color: '012169');
  }

  .component.component--inverse & {
    &:not(.btn) {
      @include button-external($color: 'fff');
    }
  }
}
