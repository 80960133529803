////
/// @group variables
/// Global spacing variables.
////

/// Individual Vars
/// ---------------
$spacing--xsmall:	rem-calc(20);
$spacing--small: rem-calc(30);
$spacing--medium: rem-calc(40);
$spacing--default: rem-calc(50);

/// Spacing Definitions
/// -------------------

/// Global Spacing
/// @type Map
$global-spacing: (
  /* 40/50/50 */
  default: (
    initial: $spacing--medium,
    large: $spacing--default,
  ),
  /* 30/40/40 */
  medium: (
    initial: $spacing--small,
    large: $spacing--medium,
  ),
  /* 20/30/30 */
  small: (
    initial: $spacing--xsmall,
    medium: $spacing--small,
  ),
  body: (
    initial: rem-calc(24px),
    medium: rem-calc(30px),
  ),
);

/// Functions and mixins for global spacing.
////

/// Generate global spacing rules.
///
/// @param {String} $size - The size of the global spacing rules to generate.
/// @param {String|List} $properties - The properties to apply the spacing to.

@mixin global-spacing($size, $properties) {
  @each $breakpoint, $spacing in map-get($global-spacing, $size) {
    @include breakpoint($breakpoint) {
      @each $type in $properties {
        #{$type}: #{$spacing};
      }
    }
  }
}

/// Generate a single global spacing rule.
///
/// @param {String} $size - The size of the global spacing rules to generate.
/// @param {String} $breakpoint - The Foundation breakpoint value to get.

@function global-spacing($size, $breakpoint) {
  $spacing: map-get($global-spacing, $size);
  @return map-get($spacing, $breakpoint);
}

/// Create global spacing classes for margin-top.
/// @see config at top of file for names and values.

@each $size, $values in $global-spacing {

  .global-spacing--#{$size},
  %global-spacing--#{$size} {

    @include global-spacing($size, margin-top);
  }
}

/// Create global padding classes.
@each $size, $values in $global-spacing {

  .global-padding--#{$size},
  %global-padding--#{$size} {

    @include global-spacing($size, padding-top);
    @include global-spacing($size, padding-bottom);
  }
}
