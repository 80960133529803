.testimonial {
  background: $white;

  .testimonial__img {
    height: 370px;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  .testimonial__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    padding: 1.5rem;
  }

  @include breakpoint(medium) {
    display: grid;
    grid-template-columns: rem-calc(360px) 1fr;
    align-items: center;

    .testimonial__img {
      height: 100%;
    }

    .testimonial__content {
      gap: 1.5rem;
      padding: 2.5rem;
    }

    &.testimonial--right {
      grid-template-columns: 1fr rem-calc(360px);

      .testimonial__img {
        order: 2;
      }
    }
  }
}

.testimonials.swiper-container {
  // Hide all but the first child if slider is not initiated.
  &:not(.swiper-pre) {
    .swiper-wrapper .swiper-slide:not(:first-child) {
      display: none;
    }
  }
}

.testimonials-section {
  background: $blue-x-light;
  padding: 2.5rem 0;

  .testimonials-section__c {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  @include breakpoint(large) {
    padding: 6rem 0;

    .testimonials-section__c {
      gap: 2.5rem;
    }
  }
}
