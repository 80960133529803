////
/// @group utilites
/// Aspect ratio mixin and default rules setup.
////

/// $aspect-ratio (val): The height divided by width of the target frame. This is added as padding bottom, so the number should be < 1 if landscape and > 1 if portrait
/// $pseudo-selector (:before/:after): the frame that holds open the mask, $aspect-ratio is converted to a percentage and added as padding-bottom
/// $target-object (string selector): almost always this is an image, but could also be a video or other content that should fit into as aspect ratio

@mixin aspect-ratio-frame(
  $aspect-ratio: 1,
  $pseudo-selector: ':before',
  $target-object: 'img',
) {
  position: relative;

  &:#{$pseudo-selector} {
    display: block;
    padding-bottom: calc(#{$aspect-ratio} * 100%);
    content: '';
  }

  #{$target-object} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @if $target-object != 'iframe' {
      object-fit: cover;
    }
  }

  iframe {
    object-fit: unset;
  }
}

/// Set the default aspect ratio
:root {
  --aspect-ratio: 1;
}

.aspect-ratio-frame {
  /// port in the default as is, also acts as a fallback.
  @include aspect-ratio-frame($target-object: 'img, video, .autoplay-video-container, iframe');

  /// reset the padding with CSS vars if supported, otherwise rely on the rules above
  @supports (--css: variables) {

    &:before {
      padding-bottom: calc(var(--aspect-ratio) * 100%);
      content: '';
    }
  }
}
