////
/// @group components
/// Styles for the image grid.
////

.image-grid {
  display: flex;
  width: 100%;
  overflow: visible;

  img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform $primary-transition;
  }

  .image-grid-item__link {
    display: block;
    @include play-button;
    @include notch;

    &:hover,
    &:focus {
      img {
        transform: scale(1.1, 1.1);
      }
    }
  }

  // Slider only.
  .image-grid__wrapper:not(.shuffle) {
    .image-grid-item__toggle {
      display: none;
    }

    .image-grid-item__link {
      @include aspect-ratio-frame($aspect-ratio: 0.56, $target-object: 'img');
    }
  }

  // Shuffle only.
  .image-grid__wrapper.shuffle {
    display: flex;
    width: calc(100% + 1.5rem); /* 0.75 is margin on both sides of items */
    margin: 0 -0.75rem; /* 1.5 / 2 */
    gap: 0 !important;

    .image-grid-item {
      display: block;
      flex: 0 0 auto;
      height: auto;
      min-height: 0;
      min-width: 0;
      width: calc(33.33333% - 1.5rem);
      margin: 0 0.75rem;
      padding: 0 0 1.5rem;
    }
  }

  // Is not a child of shuffle
  @include breakpoint(small) {
    .slider-navigation {
      display: none;
    }
  }

  .image-grid__container:not(.swiper-pre):not(.swiper-container-initialized),
  :root:not(.oho-js) & {
    .image-grid__wrapper:not(.shuffle) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem !important;
      margin: 0;

      .image-grid-item__toggle {
        display: block;
      }

      .image-grid-item {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      @include breakpoint(medium) {
        grid-template-columns: repeat(3, 1fr);
        gap: 2.5rem !important;
      }
    }
  }
}
