////
/// @group global
/// Base styles for HTML5 Video elements for any CMS.
////

a.play-button {
  @include play-button;
}

/// Autoplay video button
button.video-play-button {
  @include button-reset;
  @include play-button(
    $width: 56px,
    $button-width: 56px,
    $notch: false,
    $placement: 'bottom'
  );
}

// Responsive videos
.wysiwyg .responsive-video-iframe {
  @include aspect-ratio-frame(
    $aspect-ratio: 9/16,
    $pseudo-selector: ':before',
    $target-object: 'iframe'
  );
  min-width: 100%;
}

@include breakpoint(large) {
  .wysiwyg .align-left.responsive-video-iframe,
  .wysiwyg .align-left .responsive-video-iframe,
  .wysiwyg .align-right.responsive-video-iframe,
  .wysiwyg .align-right .responsive-video-iframe {
    min-width: 300px;
  }
}
