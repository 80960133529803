/**
 * OHO Interactive main theme stylesheet.
 * Version 2019-04-08
 * https://www.oho.com
 */

/// Import order:
/// 1.  Encoding
/// 2.  Variables
/// 3.  Foundation
/// 4.  Utilites
/// 6.  Global Styles
/// 8.  Third Party
/// 9.  Atoms
/// 10. Wrappers
/// 11. Menus
/// 12. Components
/// 13. Content Types
/// 14. Pages
/// 15. Print

/// Encoding
/// --------
@charset 'UTF-8';

/// Variables
/// ---------
@import "variables/_animation.scss";
@import "variables/_colors.scss";
@import "variables/_foundation.scss";
@import "variables/_spacing.scss";
@import "variables/_type.scss";

/// Foundation
/// ----------
/// Source: http://foundation.zurb.com/docs/
/// Notes:
/// 1. Foundation's variables are imported first. We only import the variables
///    needed for grid and flex classes.
/// 2. Modify the _foundation.scss file to alter grid settings.
/// 3. Only include XY Grid and Flex Classes.
@import 'foundation';
// Include grid classes.
@include foundation-xy-grid-classes(
  $base-grid: true,
  $margin-grid: true,
  $padding-grid: true,
  $block-grid: true,
  $collapse: true,
  $offset: true,
  $vertical-grid: true,
  $frame-grid: false
);
@include foundation-flex-classes;

/// Utilities
/// ---------
@import "utilities/_aspect-ratio.scss";
@import "utilities/_breakpoints.scss";
@import "utilities/_buttons.scss";
@import "utilities/_forms.scss";
@import "utilities/_foundation.scss";
@import "utilities/_global.scss";
@import "utilities/_notch.scss";
@import "utilities/_spacing.scss";
@import "utilities/_stack.scss";
@import "utilities/_type.scss";

/// Global Styles
/// -------------
@import "global/__layout.scss";
@import "global/_common.scss";
@import "global/_forms.scss";
@import "global/_tables.scss";
@import "global/_type.scss";
@import "global/_videos.scss";
@import "global/_wysiwyg.scss";

/// Atoms
/// -----
@import "atoms/_active-filters.scss";
@import "atoms/_alert.scss";
@import "atoms/_buttons.scss";
@import "atoms/_callout.scss";
@import "atoms/_differentiators.scss";
@import "atoms/_icon-list.scss";
@import "atoms/_notch.scss";
@import "atoms/_pager.scss";
@import "atoms/_section-heading.scss";
@import "atoms/_social-media.scss";
@import "atoms/_tags.scss";
@import "atoms/_teaser.scss";

/// Wrappers
/// --------
@import "wrapper/_cookie-banner.scss";
@import "wrapper/_footer.scss";
@import "wrapper/_header.scss";
@import "wrapper/_sock.scss";

/// Menus
/// -----
@import "menus/_accessible-menus.scss";
@import "menus/_sidebar-menu.scss";

/// Components
/// ----------
@import "components/_acad-path.scss";
@import "components/_accordion.scss";
@import "components/_animation.scss";
@import "components/_body_nav.scss";
@import "components/_breadcrumbs.scss";
@import "components/_cards-flex.scss";
@import "components/_checkerboard.scss";
@import "components/_contacts.scss";
@import "components/_content_callout.scss";
@import "components/_content-columns.scss";
@import "components/_content-left-heading.scss";
@import "components/_events-feature.scss";
@import "components/_fancybox.scss";
@import "components/_gallery.scss";
@import "components/_hero.scss";
@import "components/_image-grid.scss";
@import "components/_listing_title.scss";
@import "components/_listings.scss";
@import "components/_more-or-less.scss";
@import "components/_news-feature.scss";
@import "components/_profiles.scss";
@import "components/_promo.scss";
@import "components/_slider.scss";
@import "components/_small-image-text.scss";
@import "components/_tab.scss";
@import "components/_tabbed.scss";
@import "components/_testimonials.scss";

/// Content Types
/// -------------


/// Pages
/// -----


/// Print
/// -----
@import "print/_print.scss";
