nav.social-media {
  @include list-reset;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  a {
    display: block;
    font-size: 2rem;
    line-height: 1;
    padding: 0;

    &:hover,
    &:focus {
      color: $blue-light;
    }
  }
}
