////
/// @group menus
/// Sidebar menu styles.
////

$menu-item-spacing: 0.25rem;

.sidebar-menu {
  .sidebar-menu__heading {
    @include typeface(h6-caps);
    padding: 0 0 0.8rem;
    margin: 0;

    a {
      display: block;
      @include reset-text-decoration-only-hover;
    }
  }

  ul {
    @include list-reset;
  }

  // First level
  & > ul {
    display: flex;
    flex-direction: column;
    gap: $menu-item-spacing;
    @include notch;

    & > li {
      background-color: $blue-x-light;

      & > a,
      & > .menu-item__actions a {
        @include typeface(h6);
        display: block;
        color: $red;
        background-color: $blue-x-light;
        padding: 1rem 1rem 1rem 3rem;
        transition: background-color $primary-transition;
        text-decoration: none;

        &:focus,
        &:hover {
          background-color: $red;
          color: $white;
        }
      }

      & > .menu-item__actions {
        display: flex;
        align-items: stretch;

        a {
          display: flex;
          flex-grow: 1;
          align-items: center;
          padding-left: 0.5rem;
        }

        .menu-item__button {
          @include button-reset;
          width: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $red;
          order: -1;

          svg {
            fill: currentColor;
          }

          .menu-item__button__icon-path {
            transition: fill $primary-transition, transform $primary-transition;

            &--vertical {
              transform: scaleY(1);
              transform-origin-y: 50%;
            }
          }

          // Hide button when disabled.
          &:disabled {
            display: none;
          }

          &:hover,
          &:focus {
            background: $red;
            color: $white;
          }
        }
      }

      &.menu-item--open {
        > .menu-item__actions
          .menu-item__button
          .menu-item__button__icon-path--vertical {
          transform: scaleY(0);
        }
      }

      &.menu-item--active-trail {
        background-color: $blue-x-dark;
        color: $white;

        > .menu-item__actions {
          .menu-item__button {
            color: $blue-light;
          }
        }

        a {
          background-color: $blue-x-dark;
          color: $white;
        }

        ul.menu {
          a {
            color: $blue-light;
            text-decoration-color: $blue-light;
          }
        }
      }
    }

    // Second level
    ul.menu {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 1rem 1rem 3rem;

      a {
        display: block;
        background: transparent;
        @include typeface(body-bold);
        @include reset-text-decoration-only-hover($color: $blue-light);
      }
    }
  }

  @include breakpoint(medium down) {
    &.sidebar-menu--header {
      .sidebar-menu__heading {
        color: $white;

        a {
          @include link-on-background($bg: 'blue', $only-active: true);
        }
      }

      // First level
      & > ul.menu {
        @include notch-border;
      }
    }

    // On mobile accordion if JS:
    :root.oho-js & {
      &.sidebar-menu--accordion {
        .sidebar-menu__heading {
          @include element-invisible;
        }

        // First level
        & > ul {
          @include notch-remove;
        }
      }
    }
  }
}
