.content-left-heading {
  padding: 2.5rem 0;

  .content-left-heading__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  @include breakpoint(large) {
    padding: 4rem 0;

    .content-left-heading__container {
      flex-direction: row;
      gap: 2.5rem;
    }

    .content-left-heading__heading {
      flex-basis: 260px;
    }

    .content-left-heading__content {
      flex: 1;
    }
  }

  &.content-left-heading--blue {
	background: $blue-dark;
  }
}
