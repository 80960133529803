////
/// @group components
/// Styles for hero images.
////

.hero {
  .hero__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .hero__media {
    position: relative;

    a,
    img {
      display: block;
      margin: 0;
      padding: 0;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 64%;
      background: linear-gradient(0deg, #012169 0%, rgba(1, 33, 105, 0) 100%);
      mix-blend-mode: multiply;
      pointer-events: none;
    }
  }

  .hero__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .hero__heading {
    @include typeface(h1);
    margin: 0;
  }

  @include breakpoint(large) {
    .hero__container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
    }

    .hero__media {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }

    .hero__content {
      grid-column: 1 / -1;
      grid-row: 2 / -1;
      gap: 1.5rem;
      padding: 3rem 4rem;
    }

    .hero__heading {
      color: $white;
    }

    nav.breadcrumbs {
      @include breadcrumbs($color: 'blue-light');
    }
  }
}

.video-stream .html5-main-video {
  width: 100vw;
}

.hero-home {
  color: $white;
  padding: 0 0 2.5rem;
  overflow: hidden;

  .hero__media {
    display: block;
    width: 100%;
    position: relative;

    a {
      display: block;
      padding: 0;
    }
  }

  .hero__media__notch {
    @include notch-border($size: 24px, $after: true, $bottom: false);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: calc(100% - 2rem);
    max-width: calc(90rem - 5rem); // Grid container wide - padding
    height: calc(100% - 0.5rem);
  }

  .autoplay-video-container {
    position: absolute;
    overflow: hidden;
  }

  img {
    display: block;
    margin: 0;
    width: 100%;
  }

  video {
    display: block;
    width: 100%;
    max-width: none;
    height: auto;
    margin: 0;
    object-fit: cover;
    object-position: center;

    img {
      position: absolute;
      width: auto;
      object-fit: cover;
      object-position: center;
    }
  }


  .hero__media__button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }

  .hero__content__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 0;
    gap: 1rem;
  }

  .hero__heading {
    @include typeface(h2);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    color: $white;

    span {
      display: block;
    }
  }

  .hero__heading__caps {
    font-size: rem-calc(48px);
    line-height: 85%;
    font-weight: 900;
    text-transform: uppercase;
  }

  .hero__intro {
    @include secondary-typeface(intro-text);
    margin: 0;
  }

  @include breakpoint(small down) {

    .hero__content,
    .hero__media {
      .grid-container {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .hero__body {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .hero__content {
      @include notch-border($size: 24px, $after: true, $top: false);
    }

    .hero__differentiators {
      .grid-container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  @include breakpoint(medium) {
    padding: 0;

    .hero__content {
      border-left: 2px solid $white;
      border-right: 2px solid $white;
    }

    .hero__differentiators {
      position: relative;
    }

    .hero__differentiators__notch {
      @include notch-border($size: 24px, $width: 2px, $top: false);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 46.5%;
    }
  }

  @include breakpoint(large) {
    .hero__media__notch {
      @include notch-border($size: 24px,
        $width: 2px,
        $after: true,
        $bottom: false);
      position: absolute;
      height: calc(100% - 4.25rem);
    }

    .hero__content__container {
      padding: 4rem 0;
      gap: 3.5rem;
    }

    .hero__heading {
      gap: 1rem;
    }

    .hero__heading__caps {
      font-size: rem-calc(160px);
    }
  }

  @include breakpoint(full) {
    .hero__heading__caps {
      font-size: rem-calc(200px);
    }
  }

  .hero__video__fallback {
    display: none;
  }

  // When JavaScript doesn't load for some reason, hide play button.
  :root:not(.oho-js) & {

    video,
    .hero__media__button {
      display: none;
    }

    .hero__video__fallback {
      display: block;
    }
  }
}

.hero-landing {
  color: $white;
  padding: 0 0 1rem;
  overflow: hidden;

  .hero__container {
    @include notch($size: 24px);
    @include notch-border($size: 24px);
  }

  .hero__media {
    display: block;
    width: 100%;
    position: relative;

    a {
      display: block;
      padding: 0;
    }
  }

  .autoplay-video-container {
    position: absolute;
    overflow: hidden;
  }

  img {
    display: block;
    margin: 0;
    width: 100%;
  }

  video {
    display: block;
    width: 100%;
    max-width: none;
    height: auto;
    margin: 0;
    object-fit: cover;
    object-position: center;

    img {
      position: absolute;
      width: auto;
      object-fit: cover;
      object-position: center;
    }
  }

  .hero__media__button {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 10;
  }

  .hero__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .hero__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .hero__heading {
    @include typeface(h1);
    margin: 0;
    color: $white;
  }

  .hero__intro {
    @include secondary-typeface(intro-text);
    margin: 0;
  }

  @include breakpoint(small down) {
    .hero__media .grid-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @include breakpoint(medium down) {
    .hero__content {
      padding: 1.5rem 1rem;
    }
  }

  @include breakpoint(large) {
    padding-bottom: rem-calc(57px);

    .hero__container {
      @include notch-border($size: 24px, $width: 2px);
    }

    .hero__content {
      padding-top: 4rem;
      padding-bottom: 4rem;
      gap: 1.5rem;
    }
  }

  .hero__video__fallback {
    display: none;
  }

  // When JavaScript doesn't load for some reason, hide play button.
  :root:not(.oho-js) & {

    video,
    .hero__media__button {
      display: none;
    }

    .hero__video__fallback {
      display: block;
    }
  }
}

.hero-profile {
  padding: 1rem 0 2.5rem;

  .hero-profile__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .hero-profile__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .hero-profile__heading {
    @include typeface(h1);
    margin: 0;
  }

  .hero-profile__titles {
    @include typeface(h6);
    color: $blue-x-dark;
    margin: 0;
  }

  .hero-profile__img img {
    @include notch($size: 24);
    display: block;
    margin: 0;
  }

  @include breakpoint(large) {
    padding: 2.5rem 0 0;

    .hero-profile__container {
      flex-direction: row;
      gap: 2.5rem;
      align-items: center;
    }

    .hero-profile__content {
      flex: 1;
    }

    .hero-profile__img {
      flex-basis: 360px;
      order: -1;
    }
  }
}