////
/// @group global
/// Styles for tables.
////

$border-color: $blue-x-dark;
$th-background-color: $blue-x-dark;
$td-background-color: $blue-x-light;
$tr-alternating-bg-color: $blue-x-light;

/// Base Styles
/// -----------
table {
  @include secondary-typeface(small-text);
  margin-bottom: 2.5rem;
  border-collapse: collapse;
  border-top: 1px solid $border-color;
  width: 100%;
  text-align: left;

  a {
    @include secondary-typeface(small-text-link);
  }

  &[align='left'] {

    @include breakpoint(medium) {
      float: left;
      margin-right: 2em;
      width: auto;
    }
  }

  &[align='right'] {

    @include breakpoint(medium) {
      float: right;
      margin-left: 2em;
      width: auto;
    }
  }
}

thead,
tbody {
  vertical-align: top;
}

thead {
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;

  th {
    background: $th-background-color;
    color: $white;
    vertical-align: middle;
  }
}

tbody {
  border-top: 0;
  border-left: 1px solid $border-color;
}

tr {
  border-bottom: 1px solid $border-color;
}

@if $tr-alternating-bg-color {
  tbody tr:nth-child(even) {
    background: $tr-alternating-bg-color;
  }
}

th, td {
  padding: 0.5rem 0.5rem 0.25em;
  border-right: 1px solid $border-color;
}

th {
  border-bottom: 0;
  background: $th-background-color;
  color: $white;
  @include secondary-typeface(small-text-bold);
}

/// Responsive Tables
/// -----------------
/// Works in conjunction with table.js. Adds scrolling and a scroll indicator.
@include breakpoint(medium down) {
  .mobile-table {
    margin-bottom: 2.5rem;

    &--scroll {
      overflow-y: scroll;
    }
  }
}

/// gradient over table to indicate it is scrollable
/// ------------------------------------------------

.mobile-table {
  table {
    box-shadow: none;
  }

  &--scroll {
    overflow-y: scroll;
    transition: box-shadow 0.8s $primary-transition-function;

    $value: ();
    @for $i from 1 through 75 {
      $negative-num: -$i + px;
      $positive-num: $i / 15 + px;
      $pct: $i / 80;
      $alpha: 1 - $pct;
      $theShadow: inset $negative-num 0 $positive-num rgba(255, 255, 255, 0.05);
      $value: append($value, $theShadow, comma);
    }

    box-shadow: $value;

    @include xy-gutters($negative: true, $gutter-position: right);

    /// If the user has scrolled to the edge
    &.scrolled {
      box-shadow: none;

      table {
        z-index: 1; /// restore clicking
      }
    }

    table {
      position: relative;
    }
  }
}
