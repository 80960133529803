.notch:not(.notch--small):not(.notch--large) {
  @include notch($size: 24px);

  &.notch--border {
    @include notch-border($size: 24px);
  }
}

.notch.notch--large:not(.notch--border) {
  @include notch-large($border: false);
}
.notch.notch--large.notch--border {
  @include notch-large($border: true);
}

.notch.notch--small {
  @include notch;

  &.notch--border {
    @include notch-border;
  }
}
