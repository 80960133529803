////
/// @group wrapper
/// Sock styles.
////

.sock {
  padding: 1.5rem 0;

  &.sock--steps {
    padding: 2.5rem 0;
  }

  ul.sock__links {
    @include list-reset;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    a {
      display: block;
      background-color: transparent;
      width: 100%;
      @include typeface(h2-caps);
      text-decoration: none;
      text-align: center;
      color: $white;
      @include notch-border;
      padding: 3rem;
      transition: background-color $primary-transition;

      &:before {
        transition: width $primary-transition, height $primary-transition;
      }

      &:focus,
      &:hover {
        background-color: $blue-x-dark;
        @include notch-border($invert: true, $offset: 8px, $transition-duration: 800ms);
      }
    }

    @include breakpoint(large) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2.5rem;

      li {
        display: flex;
        flex: 1;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4rem;
        min-height: 350px;
      }
    }
  }

  .sock__steps {
    .sock__steps__c {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .sock__steps__heading {
      @include typeface(h2-caps);
      margin: 0;
      color: $white;
    }

    .sock__steps__steps {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .sock__steps__s {
      @include notch;
      background: $white;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;

      .sock__steps__body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .sock__steps__s__heading {
        @include typeface(h3-caps);
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    @include breakpoint(large) {
      .sock__steps__c {
        gap: 2.5rem;
      }

      .sock__steps__steps {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2.5rem;
      }

      .sock__steps__s {
        padding: 2.5rem;
      }
    }
  }

  @include breakpoint(large) {
    padding: 4rem 0;

    &.sock--steps {
      padding: 6rem 0;
    }
  }
}
