////
/// @group global
/// Styles relating primarily to type.
////

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/calibre-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Calibre';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/calibre-regular-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/calibre-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/calibre-black.woff2') format('woff2');
}

@font-face {
  font-family: 'LeituraNews-Roman2';
  src: url('../fonts/LeituraNewsRoman2.woff2') format('woff2'),
    url('../fonts/LeituraNewsRoman2.woff') format('woff');
}

/// Headings
/// --------
$text-elements: h1, h2, h3, h4;

@include typefaces($text-elements, true);

h5 {
  @include typeface(h5);
  @include typeface(h5-color);
}

h6 {
  @include typeface(h6);
  @include typeface(h6-color);
}

/// Margins
/// -------

// Reset margins to 0.
/*
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
iframe,
table,
.mobile-table {
  margin: 0;
}
*/

/// Text Elements
/// -------------

button.btn-link {
  @include button-reset;
}

a,
button.btn-link {
  @include typeface(body-link);
  @include reset-text-decoration;
}

em {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

.stat {
  @include secondary-typeface(stat);
}

figure.blockquote {
  background: $blue-x-light;
  margin: 0 auto;
  padding: 1.5rem;
  @include notch-large($border: false);

  blockquote {
    background: transparent;
	margin: 0;
    padding: 0;

    p {
      @include secondary-typeface(blockquote);
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  figcaption {
    @include typeface(h6-caps);
    @include typeface(h6-color);
    margin-top: 1rem;
  }

  @include breakpoint(large) {
    padding: 2.5rem;

    figcaption {
      margin-top: 1.5rem;
    }
  }
}

figure.blockquote-attribution {
  background: transparent;
  margin: 0;
  padding: 0;

  svg {
    width: 3rem;
    height: 2rem;
    margin: 0 0 1rem;
    color: $blue-x-dark;
  }

  blockquote {
    background: transparent;
    margin: 0;
    padding: 0;
    color: $black;

    p {
      @include secondary-typeface(blockquote);
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  figcaption {
    @include typeface(h6-caps);
    @include typeface(h6-color);
    font-style: normal;
    color: inherit;
    color: $blue-x-dark;
    margin-top: 1rem;

    .blockquote__caption__addtl {
      display: block;
      @include secondary-typeface(small-text);
      color: $black;
      text-transform: none;
      margin: 0.5rem 0 0;
    }
  }

  @include breakpoint(large) {
    svg {
      width: 4rem;
      height: 2.6875rem;
      margin-bottom: 1.5rem;
    }

    figcaption {
      margin-top: 1.5rem;
    }
  }
}

/// --- Custom List Styles

/// Global

ol,
ul {
  margin: 0;
  @include global-spacing(medium, margin-top);
  padding: 0;
}

li {
  margin: 0.5rem 0 0 1rem;
}

ul {
  margin-left: 1.2rem;

  & > li {
    padding: 0 0 0 0.5rem;
  }
}

ol {
  list-style: none;
  counter-reset: li;

  & > li {
    padding: 0 0 0 1.75rem;
  }
}

ol > li {
  position: relative;
  counter-increment: li;

  ol{
    li{
        &::before {
        position: absolute;
        top: rem-calc(0);
        left: rem-calc(0);
        display: inline-block;
        content: counter(li) '.';
        color: $blue-x-dark;
        font-size: rem-calc(18);
        font-weight: 600;
      }
    }
  }
}

ol+ol{
  li{
    &::before {
      position: absolute;
      top: rem-calc(0);
      left: rem-calc(0);
      display: inline-block;
      content: counter(li) '.';
      color: $blue-x-dark;
      font-size: rem-calc(18);
      font-weight: 600;
    }
  }
}

/// Unordered Lists
ul > li {
  &::marker {
    color: $blue-x-dark;
    font-size: 1.1em;
  }
}

ol,
ul {
  ol,
  ul {
    margin-left: 0;
    @include global-spacing(small, margin-top);
    @include global-spacing(small, margin-bottom);
  }
}

.list--comma-separate {
  ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 0.25em;
    }

    &:nth-last-child(n + 2):after {
      content: ',';
    }
  }
}

.small-text {
  @include secondary-typeface(small-text);
}

.small-text a,
a.small-text {
  @include secondary-typeface(small-text-link);
}

strong.small-text,
.small-text-bold,
.small-text strong {
  @include secondary-typeface(small-text-bold);
}

a.small-text,
.small-text a {
  @include secondary-typeface(small-text-link);
}
