////
/// @group variables
/// Site-wide colors.
////

/// Color Definitions
/// -----------------

/// White and black. Using variables because sometimes these can be slightly
/// lighter or darker than the absolute values, on a per-project basis.
/// @type Color
$white: #ffffff;
$black: #000000;

$white-opacity: rgba(255, 255, 255, 0.5);

/// The color of text.
/// @type Color
$text-color: #2e312f;

/// The theme grays.
/// @type Color
$gray: mix(#000000, #ffffff, 50%);
$gray--dark: mix(#000000, #ffffff, 85%);
$gray--medium: mix(#000000, #ffffff, 65%);
$gray--light: mix(#000000, #ffffff, 35%);
$gray--xlight: mixz(#000000, #ffffff, 15%);

/// Theme specific colors
$red: #ba0c2f;
$red-light: #e40046;
$red-light-opacity-half: rgba(228, 0, 70, 0.5);
$blue-light-opacity-half: rgba(89, 203, 232, 0.5);
$blue-light: #59cbe8;
$blue-x-light: #eefafd;
$blue-dark: #004c97;
$blue-x-dark: #012169;
$yellow: #f2c752;

/// The theme primary colors.
/// @type Color
$primary-color: $red;
$primary-color--dark: darken($primary-color, 20%);
$primary-color--light: $red-light; //lighten($primary-color, 10%);

/// Theme accent colors.
/// @type Color
$accent-blue-light: $blue-light;
$accent-red: $red;
$accent-red-light: $red-light;
$accent-yellow: $yellow;

/// Theme message colors.
/// @type Color
$message-success: #3c763d;
$message-warning: #e09600;
$message-alert: #a94442;

/// Color Aliases
/// -------------
$link-color: $primary-color;
