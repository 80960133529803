.content-columns {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .content-columns__heading {
    @include typeface(h4-caps);
    color: $white;
    margin: 0;
  }

  .content-columns__content {
    @include secondary-typeface(small-text);
  }

  .content-columns__contact,
  .content-columns__contact__sites {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .content-columns__contact__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  ul.menu {
    @include list-reset;
    width: 100%;
    column-count: 2;
    column-gap: 1rem;

    li,
    a {
      display: inline-block;
      width: 100%;
      break-inside: avoid;
      page-break-inside: avoid;
      -webkit-column-break-inside: avoid;
      background: $blue-x-dark;
      border: 1px solid $blue-x-dark;
      margin: 0;
      overflow: visible;
    }

    li {
      display: block;
    }

    a {
      margin: 0 0 0.5rem;
      @include secondary-typeface(small-text-link);
      @include link-on-background($only-active: true);
    }
  }

  @include breakpoint(large) {
    display: grid;
    grid-template-columns: minmax(auto, rem-calc(360px)) 1fr;
    gap: 2.5rem;

    .content-columns__content {
      padding-top: 0.1rem;
    }

    .content-columns__contact {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2.5rem;
    }

    .content-columns__contact__icons {
      grid-column: 1 / span 2;

      ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        column-gap: 2.5rem;
        row-gap: 1rem;
      }
    }

    .content-columns__contact__sites {
      grid-column: 3 / -1;
    }

    ul.menu {
      column-count: 4;
      column-gap: 2.5rem;

      // So it aligns with heading.
      padding-top: 0.25rem;

      a {
        margin-bottom: 1rem;
      }
    }
  }
}

.content-columns-section {
  background: $blue-x-dark;
  color: $white;
  padding: 2.5rem 0;

  a:not(.btn) {
    @include link-on-background;
  }

  @include breakpoint(large) {
    padding: 4rem 0;
  }
}
