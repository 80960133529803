.contacts {
  background: $blue-dark;
  padding: 2rem 1.5rem;
  color: $white;
  @include notch($size: 24px);

  .contacts__item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 0 0 0;
    margin: 1.5rem 0 0 0;
    position: relative;

    a {
      margin: 0;
    }

    p {
      margin: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      background: $white;
      opacity: 0.5;
    }

    &:first-child {
      padding-top: 0;
      margin-top: 0;

      &:before {
        display: none;
      }
    }
  }

  .contacts__item__img {
    @include notch($size: 16px);
    order: -1;
    width: 120px;
    height: auto;
    margin: 0;
  }

  .contacts__item__heading {
    @include typeface(h3);
    margin: 0;
    color: $white;
  }

  .contacts__item__heading a {
    @include typeface(h3);
    display: block;
    margin: 0;
    @include link-on-background;

    &:hover,
    &:focus {
      background-color: $blue-dark;
      text-decoration: underline 2px solid $blue-light;
      text-decoration-line: underline;
      text-decoration-color: $blue-light;
      text-decoration-style: solid;
      text-decoration-thickness: 2px;
    }
  }

  .contacts__item__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .contacts__item__meta,
  .contacts__item__body {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
  }

  @include breakpoint(large) {
    padding: 2.5rem;

    .contacts__item {
      flex-direction: row;
      gap: 2.5rem;
      align-items: flex-start;
    }

    .list-icons {
      flex-basis: 220px;
    }

    .contacts__item__content {
      flex: 1;
      flex-direction: row;
      gap: 2.5rem;
    }

    .contacts__item__body {
      gap: 1rem;
    }
  }
}
