////
/// @group components
/// Styles for animations.
////

@keyframes swipeInDiagonal {
  0% {
    clip-path: polygon(20% 0%, 21% 0%, 71% 100%, 70% 100%);
    transform: scale(0.75, 0.75);
    opacity: 0;
  }

  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.75, 0.75);
    transform-origin: center;
  }

  100% {
    transform: scale(1, 1);
    transform-origin: center;
  }
}

@media (prefers-reduced-motion: no-preference) {
  html.oho-js-pre {
    .oho-animate {
      &.oho-animate-swipe-diagonal {
        opacity: 0;
        transform: scale(0.75, 0.75);

        &.oho-animate--in {
          opacity: 1;
          transform: scale(1, 1);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
          animation-name: swipeInDiagonal;
          animation-duration: 1000ms;
          animation-timing-function: ease-out;
        }

        // When JavaScript doesn't load for some reason, show the content.
        :root:not(.oho-js) & {
          opacity: 1;
        }
      }

      &.oho-animate-fade-in {
        opacity: 0;

        &.oho-animate--in {
          opacity: 1;
          animation-name: fadeIn;
          animation-duration: 2000ms;
          animation-timing-function: ease-out;
        }

        // When JavaScript doesn't load for some reason, show the content.
        :root:not(.oho-js) & {
          opacity: 1;
        }
      }

      &.oho-animate-zoom-in {
        &.oho-animate-item,
        .oho-animate-item {
          transform: scale(0.75, 0.75);

          // When JavaScript doesn't load for some reason, show the content.
          :root:not(.oho-js) & {
            transform: scale(1, 1);
          }
        }

        &.oho-animate--in {
          &.oho-animate-item,
          .oho-animate-item {
            transform: scale(1, 1);
            animation-name: zoomIn;
            animation-duration: 500ms;
            animation-timing-function: ease-out;
          }
        }
      }
    }
  }
}
