.cards-flex-section {
  padding: 2.5rem 0 0;
  color: $white;

  a:not(.btn) {
    @include link-on-background;
  }

  // To have card overflow.
  .silhouette__bg {
    height: calc(100% - 1.5rem);
  }

  .cards-flex-section__container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .cards-flex__wrapper {
    display: flex;
  }

  .cards-flex__item {
    width: 100%;
    display: flex;
    height: auto;
  }

  @include breakpoint(medium down) {
    .cards-flex__item {
      width: 100%;
    }
  }

  @include breakpoint(medium) {
    .cards-flex:not(.swiper-pre) .cards-flex__item {
      flex: 1;
	  min-width: rem-calc(360px);
    }
    .cards-flex.swiper-pre .cards-flex__item {
      width: rem-calc(360px);
    }
  }

  @include breakpoint(large) {
    padding-top: 6rem;

    .cards-flex-section__container {
      gap: 2.5rem;
    }
  }
}
