////
/// @group components
/// Styles for the cookie banner.
////

@keyframes slide-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: none;
  }
}

.cookie-banner {
  background-color: $blue-dark;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 3rem 0;
  width: 100%;
  transition: transform 1s $primary-transition-function;
  transform: translateY(100%);
  color: $white;
  z-index: 1000;

  .btn {
    margin: 0;
  }

  .cookie-banner__container {
    display: grid;
    gap: 1rem;
  }

  .cookie-banner__message {
    font-size: 20px;
    line-height: 100%;

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  .cookie-banner__actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  @include breakpoint(small down) {

	// Giving buttons slightly less padding to make sure there is more room for icon.
    .cookie-banner__actions .btn {
      padding: 0.75rem 1.25rem;
    }
  }

  @include breakpoint(large) {
    .cookie-banner__container {
      grid-template-columns: 1fr auto;
      align-items: center;
    }
  }
}

html.oho-js body:not(.cookie-panel-dismissed) {
  .cookie-banner {
    animation: slide-in;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-duration: 1s;
    animation-delay: 3s;
  }
}
