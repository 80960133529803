////
/// @group components
/// Styles for accordions elements.
////

/// The animation duration, in ms. Should align with the value in accordion.js
/// so that the border bottom doesn't appear too soon.
/// @type Number
$accordion-animation-duration: 400ms;

.accordion {
  width: 100%;
  clear: both;
  background-color: $white;
  @include notch;

  .accordion__button {
    display: block;
    background-color: transparent;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0 1.5rem;
    @include typeface(h4);
    color: $red;
    text-align: left;
    transition: background-color $primary-transition, color $primary-transition;

    .accordion__button__inner {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem 0;
    }

    svg {
      fill: currentColor;
    }

    .accordion__icon {
      width: 0.75em;
      height: 0.75em;

      @include breakpoint(medium) {
        min-width: rem-calc(18);
        min-height: rem-calc(18);
      }
    }

    .accordion__icon-path {
      transition: fill $primary-transition, transform $primary-transition;

      &--vertical {
        transform: scaleY(1);
        transform-origin-y: 50%;
      }
    }

    // Hide button icon when disabled.
    &:disabled {
      svg {
        display: none;
      }
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: $red;
      color: $white;
    }

    @include breakpoint(large) {
      padding: 0 2.5rem;

      .accordion__button__inner {
        padding: 1.5rem 0;
      }
    }
  }

  .accordion__content {
    @include clearfix;
    display: none;
    padding: 0 1.5rem;

    .accordion__content__inner {
      padding: 1rem 0;
    }

    @include breakpoint(large) {
      padding: 0 2.5rem;

      .accordion__content__inner {
        padding: 1.5rem;
      }
    }

    // When JavaScript doesn't load for some reason, show the content.
    :root:not(.oho-js) & {
      display: block;
    }
  }

  &.accordion--open {
    background-color: $blue-x-light;
  }

  // Open state, or when JavaScript doesn't load for some reason.
  &.accordion--open>.accordion__button,
  :root:not(.oho-js) .accordion__button {
    background-color: $blue-x-light;
    transition: background-color $primary-transition, color $primary-transition;

    .accordion__button__inner {
      border-bottom: 1px solid $blue-light-opacity-half;
    }

    .accordion__icon-path--vertical {
      transform: scaleY(0);
    }
  }

  /// Mobile-only accordions
  /// ----------------------
  &.accordion--mobile {
    .accordion__button {
      background-color: $red;
      @include typeface(h6);
      color: $white;

      &:hover,
      &:focus {
        background: $blue-dark;
      }
    }

    .accordion__content {
      background-color: $white;
      padding: 0;
    }

    .accordion__content__inner {
      padding: 0;
    }

    .accordion__button:disabled {
      display: none;

      +.accordion__content {
        // We always want to show the content in desktop, regardless of the
        // toggled state that happened in mobile.
        display: block !important; // stylelint-disable-line
      }
    }
  }

  &.accordion--secondary {

    a,
    button.btn-link {
      @include link-on-background;
    }

    select {

      &:hover,
      &:focus {
        background-color: $blue-x-dark;
      }
    }

    .accordion__button {
      @include typeface(h6);
      color: $red;
      margin: 0;
      padding: 1rem 1.5rem 0;

      .accordion__button__inner {
        padding: 0 0 1rem;
      }

      &:focus:before,
      &:hover:before {
        display: none;
      }

      @include breakpoint(large) {
        padding: 1.5rem 1.5rem 0;

        .accordion__button__inner {
          padding-bottom: 1.5rem;
        }
      }
    }

    .accordion__button:not(:disabled):hover,
    .accordion__button:not(:disabled):focus,
    &.accordion--open .accordion__button {
      background-color: $blue-x-dark;
      color: $white;
    }

    .accordion__content {
      background-color: $blue-dark;
      color: $white;
      padding: 0.5rem 1.5rem 1.5rem;

      .accordion__content__inner {
        padding: 0;
      }

      @include breakpoint(large) {
        padding: 1rem 2rem 1.5rem;
      }
    }

    &.accordion--basic {
      background-color: transparent;
      @include notch-remove;

      .accordion__button,
      .accordion__button__inner {
        transition: background-color $primary-transition,
          padding $primary-transition;
      }

      &:not(.accordion--open) .accordion__button:not(:focus):not(:hover) {
        padding: 0;

        .accordion__button__inner {
          padding: 0;
        }
      }

      &.accordion--open,
      &:hover,
      &:focus-within {
        @include notch;
      }
    }

    &.accordion--red {
      background-color: $red;

      .accordion__button {
        background-color: $red;
        color: $white;
      }
    }

    &.accordion--open {
      background-color: $blue-dark;
      color: $white;

      .accordion__button {
        background-color: $blue-dark;
        color: $white;
      }

      .accordion__button:not(:disabled):hover,
      .accordion__button:not(:disabled):focus {
        background-color: $red;
        color: $white;
      }

      &.accordion--secondary {

        .accordion__button:not(:disabled):hover,
        .accordion__button:not(:disabled):focus {
          background-color: $blue-x-dark;
          color: $white;
        }
      }
    }
  }
}

.accordions {
  @include notch;

  .accordion {
    @include notch-remove;

    &:not(.accordion--open) {
      .accordion__button .accordion__button__inner {
        border-bottom: 1px solid $red-light-opacity-half;
      }

      &:last-child {
        .accordion__button .accordion__button__inner {
          border-bottom: 0;
        }
      }
    }

    .accordion__button:not(:disabled) {

      &:hover,
      &:focus {
        .accordion__button__inner {
          border-bottom-color: transparent;
        }
      }
    }
  }
}