@mixin notch-top-right($size: 16px, $invert: false) {
  $inset: 0px;
  @if $invert == true {
    $inset: 0 - $size;
  }
  clip-path: polygon(
    0% 0%,
    calc(100% - #{$size + $inset}) 0%,
    100% #{$size + $inset},
    100% 100%,
    0% 100%
  );
}

@mixin notch-remove {
  clip-path: unset;
}

@mixin notch-border-path(
  $size: 16px,
  $width: 1px,
  $offset: 0px,
  $invert: false
) {
  $inset: 0px;
  $offset_angle: $offset / 2;
  $width_angle: $offset_angle - ($width / 2);
  @if $invert == true {
    $inset: 0px - $size;
    $offset_angle: 0px;
    $width_angle: 0px;
  }
  @if $offset == 0 {
	clip-path: polygon(
		$offset $offset,
		calc(100% - #{(($size + $inset)) - $offset_angle}) $offset,
		100% #{(($size + $inset)) - $offset_angle},
		100% 100%,
		#{(($size + $inset)) - $offset_angle} 100%,
		$offset calc(100% - #{(($size + $inset)) - $offset_angle}),
		$width calc(100% - #{(($size + $inset)) - $width_angle}),
		#{(($size + $inset)) - $width_angle} calc(100% - #{$width}),
		calc(100% - #{$width}) calc(100% - #{$width}),
		calc(100% - #{$width}) #{(($size + $inset)) - $width_angle},
		calc(100% - #{(($size + $inset)) - $width_angle}) $width,
		$width $width,
		$width calc(100% - #{(($size + $inset)) - $width_angle}),
		$offset calc(100% - #{(($size + $inset)) - $offset_angle}),
		$offset $offset
	);
  } @else {
	clip-path: polygon(
		$offset $offset,
		calc(100% - #{($offset + ($size + $inset)) - $offset_angle}) $offset,
		calc(100% - #{$offset}) #{($offset + ($size + $inset)) - $offset_angle},
		calc(100% - #{$offset}) calc(100% - #{$offset}),
		#{($offset + ($size + $inset)) - $offset_angle} calc(100% - #{$offset}),
		$offset calc(100% - #{($offset + ($size + $inset)) - $offset_angle}),
		#{$offset + $width} calc(100% - #{($offset + ($size + $inset)) - $width_angle}),
		#{($offset + ($size + $inset)) - $width_angle} calc(100% - #{$offset + $width}),
		calc(100% - #{$offset + $width}) calc(100% - #{$offset + $width}),
		calc(100% - #{$offset + $width}) #{($offset + ($size + $inset)) - $width_angle},
		calc(100% - #{($offset + ($size + $inset)) - $width_angle}) #{$offset + $width},
		#{$offset + $width} #{$offset + $width},
		#{$offset + $width} calc(100% - #{($offset + ($size + $inset)) - $width_angle}),
		$offset calc(100% - #{($offset + ($size + $inset)) - $offset_angle}),
		$offset $offset
	);
  }
}

@mixin notch-border-path-no-top(
  $size: 16px,
  $width: 1px,
  $offset: 0px,
  $invert: false
) {
  $inset: 0px;
  $offset_angle: $offset / 2;
  $width_angle: $offset_angle - ($width / 2);
  @if $invert == true {
    $inset: 0px - $size;
    $offset_angle: 0px;
    $width_angle: 0px;
  }
  clip-path: polygon(
    $offset $offset,
    $offset calc(100% - #{($offset + ($size + $inset)) - $offset_angle}),
    #{($offset + ($size + $inset)) - $offset_angle} calc(100% - #{$offset}),
    calc(100% - #{$offset}) calc(100% - #{$offset}),
    calc(100% - #{$offset}) $offset,
    calc(100% - #{$offset + $width}) $offset,
    calc(100% - #{$offset + $width}) calc(100% - #{$offset + $width}),
		#{($offset + ($size + $inset)) - $width_angle} calc(100% - #{$offset + $width}),
    #{$offset + $width} calc(100% - #{($offset + ($size + $inset)) - $width_angle}),
    #{$offset + $width} $offset
  );
}

@mixin notch-border-path-no-bottom(
  $size: 16px,
  $width: 1px,
  $offset: 0px,
  $invert: false
) {
  $inset: 0px;
  $offset_angle: $offset / 2;
  $width_angle: $offset_angle - ($width / 2);
  @if $invert == true {
    $inset: 0px - $size;
    $offset_angle: 0px;
    $width_angle: 0px;
  }
  clip-path: polygon(
    $offset calc(100% - #{$offset}),
    $offset $offset,
    calc(100% - #{($offset + ($size + $inset)) - $offset_angle}) $offset,
    calc(100% - #{$offset}) #{($offset + ($size + $inset)) - $offset_angle},
    calc(100% - #{$offset}) calc(100% - #{$offset}),
    calc(100% - #{$offset + $width}) calc(100% - #{$offset}),
    calc(100% - #{$offset + $width}) #{($offset + ($size + $inset)) - $width_angle},
	calc(100% - #{($offset + ($size + $inset)) - $width_angle}) #{$offset + $width},
    #{$offset + $width} #{$offset + $width},
    #{$offset + $width} calc(100% - #{$offset})
  );
}

@mixin notch-border-path-no-bottom-notch(
  $size: 16px,
  $width: 1px,
  $offset: 0px,
  $invert: false
) {
  $inset: 0px;
  $offset_angle: $offset / 2;
  $width_angle: $offset_angle - ($width / 2);
  @if $invert == true {
    $inset: 0px - $size;
    $offset_angle: 0px;
    $width_angle: 0px;
  }
  clip-path: polygon(
    $offset calc(100% - #{$offset}),
    $offset $offset,
    calc(100% - #{($offset + ($size + $inset)) - $offset_angle}) $offset,
    calc(100% - #{$offset}) #{($offset + ($size + $inset)) - $offset_angle},
    calc(100% - #{$offset}) calc(100% - #{$offset}),
	$offset calc(100% - #{$offset}),
	$offset calc(100% - #{$offset + $width}),
    calc(100% - #{$offset + $width}) calc(100% - #{$offset + $width}),
    calc(100% - #{$offset + $width}) #{($offset + ($size + $inset)) - $width_angle},
	calc(100% - #{($offset + ($size + $inset)) - $width_angle}) #{$offset + $width},
    #{$offset + $width} #{$offset + $width},
    #{$offset + $width} calc(100% - #{$offset})
  );
}

@mixin notch-border-content(
  $size: 16px,
  $width: 1px,
  $color: $white,
  $offset: 0px,
  $invert: false,
  $z-index: 0,
  $bottom: true,
  $bottom_notch: true,
  $top: true,
  $opacity: 1,
  $transition-duration: $primary-transition-time,
  $transition-function: $primary-transition-function
) {
  content: '';
  display: block;
  background: $color;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index + 1;
  opacity: $opacity;
  transition: all $transition-duration $transition-function;

  @if $bottom_notch == false {
    @include notch-border-path-no-bottom-notch(
      $size: $size,
      $width: $width,
      $invert: $invert,
      $offset: $offset
    );
  } @else if $bottom == false and $top == true {
    @include notch-border-path-no-bottom(
      $size: $size,
      $width: $width,
      $invert: $invert,
      $offset: $offset
    );
  } @else if $bottom == true and $top == false {
    @include notch-border-path-no-top(
      $size: $size,
      $width: $width,
      $invert: $invert,
      $offset: $offset
    );
  } @else {
    @include notch-border-path(
      $size: $size,
      $width: $width,
      $invert: $invert,
      $offset: $offset
    );
  }
}
@mixin notch-border(
  $size: 16px,
  $width: 1px,
  $color: $white,
  $offset: 0px,
  $invert: false,
  $z-index: 0,
  $after: false,
  $bottom: true,
  $bottom_notch: true,
  $top: true,
  $opacity: 1,
  $transition-duration: $primary-transition-time,
  $transition-function: $primary-transition-function
) {
  position: relative;
  z-index: $z-index;

  @if $after == true {
    &:after {
      @include notch-border-content(
        $size: $size,
        $width: $width,
        $color: $color,
        $offset: $offset,
        $invert: $invert,
        $z-index: $z-index,
        $bottom: $bottom,
        $bottom_notch: $bottom_notch,
        $top: $top,
        $opacity: $opacity,
        $transition-duration: $transition-duration
      );
    }
  } @else {
    &:before {
      @include notch-border-content(
        $size: $size,
        $width: $width,
        $color: $color,
        $offset: $offset,
        $invert: $invert,
        $z-index: $z-index,
        $bottom: $bottom,
        $bottom_notch: $bottom_notch,
        $top: $top,
        $opacity: $opacity,
        $transition-duration: $transition-duration
      );
    }
  }
}

@mixin notch(
  $size: 16px,
  $invert: false,
  $transition-duration: $primary-transition-time,
  $transition-function: $primary-transition-function
) {
  $inset: 0px;
  @if $invert == true {
    $inset: 0 - $size;
  }
  transition: clip-path $transition-duration $transition-function;
  clip-path: polygon(
    0% 0%,
    calc(100% - #{$size + $inset}) 0%,
    100% #{$size + $inset},
    100% 100%,
    #{$size + $inset} 100%,
    0% calc(100% - #{$size + $inset})
  );
}

@mixin notch-large($border: false, $border-z-index: 0) {
  @include notch($size: 24px);

  @if $border == true {
    @include notch-border($size: 24px, $z-index: $border-z-index);
  }

  @include breakpoint(medium) {
    @include notch($size: 40px);

    @if $border == true {
      @include notch-border($size: 40px);
    }
  }
}
