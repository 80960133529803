.promo {
  padding: 1.5rem 0;

  p {
    margin: 0;
  }

  .promo__container {
    background: $yellow;
  }

  .promo__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
  }

  .promo__body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .promo__heading {
    @include typeface(h3);
    text-transform: uppercase;
    margin: 0;
  }

  .promo__buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .promo__img {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 0.58;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include breakpoint(medium) {
    padding: 2.5rem 0;

    .promo__container {
      display: grid;
      grid-template-columns: 46% 1fr;
      align-items: stretch;
    }

    .promo__content {
      padding: 2rem 2.5rem;
      gap: 1.5rem;
    }

    .promo__buttons {
      gap: 0.75rem;
    }
  }

  @include breakpoint(full) {
    .promo__container {
      grid-template-columns: 56% 5fr 1fr;
      gap: 2.5rem;
    }

    .promo__content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
