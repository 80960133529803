.content-callout.listing-title {
  padding: 1rem 0 2.5rem;

  p {
    margin: 0;
  }

  .content-callout__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .listing-title__programs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .listing-title__program {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .listing-title__program__h {
    @include typeface(h4);
    margin: 0;
  }

  @include breakpoint(large) {
    padding: 2.5rem 0 6rem;

    .content-callout__content {
      gap: 2.5rem;
    }

    .content-callout__body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .listing-title__programs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5rem;
    }
  }
}
